import * as REDUX_TYPES from '../types'

export const setAccruedCredits = (accruedCredits) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_ACCRUED_CREDITS,
    accruedCredits
  }
}
export const setAccruedCreditsRoom = (accruedCreditsRoom) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_ACCRUED_CREDITS_ROOM,
    accruedCreditsRoom
  }
}
export const setAutoPlay = (autoPlay) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_AUTO_PLAY,
    autoPlay,
  }
}

export const setCollections = (collections) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_COLLECTIONS,
    collections,
  }
}

export const setCreditPlayTime = (creditPlayTime) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_CREDIT_PLAY_TIME,
    creditPlayTime,
  }
}

export const setCurrentCollection = (currentCollection) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_CURRENT_COLLECTION,
    currentCollection,
  }
}
export const setEngaged = (engaged) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_ENGAGED,
    engaged,
  }
}
export const setFullscreen = (fullscreen) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_FULLSCREEN,
    fullscreen,
  }
}

export const setHideOverlay = (hideOverlay) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_HIDE_OVERLAY,
    hideOverlay,
  }
}

export const setPlayList = (playList) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_PLAYLIST,
    playList,
  }
}

export const setPlayListIndex = (playListIndex) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_PLAYLIST_INDEX,
    playListIndex,
  }
}

export const setPlayerMode = (playerMode) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_PLAYER_MODE,
    playerMode,
  }
}

export const setPlayTime = (playTime) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_PLAY_TIME,
    playTime,
  }
}

export const setQueuePlay = (queuePlay) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_QUEUE_PLAY,
    queuePlay,
  }
}

export const setReactorPlay = (reactorPlay) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_REACTOR_PLAY,
    reactorPlay,
  }
}

export const setShowAi = (showAi) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_SHOW_AI,
    showAi,
  }
}

export const setShowAttachments = (showAttachments) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_SHOW_ATTACHMENTS,
    showAttachments,
  }
}


export const setShowChat = (showChat) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_SHOW_CHAT,
    showChat,
  }
}

