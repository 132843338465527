import React from 'react'
import Button from 'components/CustomButtons/Button.js'
import Editor from 'react-simple-wysiwyg'
import { useState } from 'react'
import { TextField } from '@material-ui/core'
import { DeleteForeverTwoTone, HighlightOffTwoTone, PostAddTwoTone } from '@material-ui/icons'
import MediaDrop from 'components/media/MediaDrop.js'
import { resize } from 'util/imageUtils.js'
import ErrorLine from 'components/ErrorLine.js'
import { uploadMedia } from 'controllers/VortexController.js'
import { useDispatch, useSelector } from 'react-redux'
import { getStorageAccount } from 'redux/actions/accountActions.js'
import TimerProgress from 'components/mint/TimerProgress.js'
import { getStorageAccessToken } from 'redux/actions/accountActions.js'
import { createValidEmbed } from 'util/embedUtils.js'
import { parseMessageForUrl } from 'util/postUtils.js'
import { useEffect } from 'react'
import { createLyrics } from 'util/documentUtils.js'
import { displayError } from 'util/screenUtils.js'
import { deleteMessageHLS } from 'controllers/VortexController.js'
import HarmonizeHeader from 'components/Header/HarmonizeHeader.js'
import Footer from 'components/Footer/Footer.js'
import MediaIcons from 'components/media/MediaIcons'
import ModalDialog from 'components/ModalDialog'
//import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { setSongDescription } from 'redux/actions/songActions'
import { setSongSummary } from 'redux/actions/songActions'
import { resetSong } from 'redux/actions/songActions'
import { setSongTitle } from 'redux/actions/songActions'
import { setSongMp3 } from 'redux/actions/songActions'
import { setSongMediaIcons } from 'redux/actions/songActions'
import { setSongImage } from 'redux/actions/songActions'
import Genres from 'components/utility/Genres'
import { addArtistStudios } from 'controllers/HarmonizeController'
import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom'
import { setSongLyrics } from 'redux/actions/songActions'
import { setSongGenre } from 'redux/actions/songActions'
import { MESSAGE_STATUS } from 'util/postUtils'
import { APPROVAL_STATUS } from 'util/postUtils'
import { MEDIA_TYPES } from 'util/postUtils'
import { DENIAL_REASONS } from 'util/postUtils'
import { Link, useHistory } from 'react-router-dom'
import { UPLOAD_MEDIA_TYPES } from 'util/postUtils'
import roundBrain from 'images/round-brain.png'
import AIChat, { aiChatStyles } from './AIChat'
import { getMediaThumbnail } from 'util/imageUtils'
import { getMediaAudio } from 'util/imageUtils'
import { isSupport } from 'util/postUtils'

const buttonStyles = {
  action: { cursor: 'pointer', marginRight: '1em' }
}
export default function CreateHarmonizePost({ socket, close, messageId }) {
  const isEditing = messageId !== null
  //console.log('song', useSelector(state => state.song))
  const { description = '', genre, image, lyrics = '', mediaIcons, mp3, summary = '', title = '' } = useSelector(state => state.song)
  const [error, setError] = useState()
  const [studios, setStudios] = useState()
  const [sending, setSending] = useState(false)
  const [messageToEdit, setMessageToEdit] = useState({ status: MESSAGE_STATUS.DRAFT })
  const [descriptionLength, setDescriptionLength] = useState(description.length)
  const [summaryLength, setSummaryLength] = useState(summary.length)
  const [titleLength, setTitleLength] = useState(title.length)
  const [embedContent, setEmbedContent]
    = useState('')
  const [showAddLyrics, setShowAddLyrics] = useState(false)
  //const [showPublishPrompt, setShowPublishPrompt] = useState(false)
  //const [showCalendar, setShowCalendar] = useState(false)
  const [lyricsLength, setLyricsLength] = useState(lyrics.length)
  const [showUpload, setShowUpload] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmRights, setConfirmRights] = useState(false)
  const [confirmDeleteSource, setConfirmDeleteSource] = useState()
  const [confirmMove, setConfirmMove] = useState(false)
  const [confirmPublish, setConfirmPublish] = useState(0) //0=none 1=publish 2=reepublish
  const [confirmUnpublish, setConfirmUnpublish] = useState(false)
  const [showAi, setShowAi] = useState(false)
  const { firstName, lastName, handle, username, id: userId } = getStorageAccount()

  const accessToken = getStorageAccessToken()
  const { currentChannel, currentRoom, messages } = useSelector(state => state.messages)
  const { currentAsset } = useSelector((state) => state.asset)
  const history = useHistory()

  const displayConfirmRights = () => {
    return (
      `I ${firstName} ${lastName} confirm that, with respect to the files I am about to upload:
      
      <ol>
      <li>I own 100% of the songwriting rights</li>
      <li>I own 100% of the publishing rights</li>
      <li>This is not a cover song, or it is a cover of a song in the public domain</li>
      <li>Either the image is not copyrighted, or I own the image copyright
      </ol>
      `

    )
  }
  const dispatch = useDispatch()

  /** Connect to the channel saved in the parent Harmonize connection. You can't really save the socket across pages because
 * we need to disconnect when visibility changes occur.
 * @param {*} type 
 * @param {*} message 
 */
  const safeEmit = (type, message) => {
    if (socket()) {
      socket().emit(type, message)
    }
  }
  /** If isEditing send updateMessage, otherwise message
   * @param {*} messageToSend 
   */
  const sendToSocket = (messageToSend) => {
    try {
      safeEmit(isEditing ? 'updateMessage' : 'message', messageToSend)
      setSending(false)
    } catch (error) {
      displayError(error, setError, 1)
    }
  }

  /**
   * Reset so that a new song can be created
   */
  const clearSong = () => {
    dispatch(resetSong())
    setError('')
    setShowUpload(false)
    setEmbedContent('')
    setShowUpload(true)
  }

  const closePost = (clear) => {
    if (clear) { clearSong() }
    close()
  }

  /**
   * 
   * @returns The maximum uploaded image size
   */
  const getUploadImageSize = () => {
    const parsed = parseInt(process.env.REACT_APP_UPLOAD_IMAGE_SIZE)
    return isNaN(parsed) ? 120 : parsed
  }

  const getUploadImageQuality = () => {
    return parseFloat(process.env.REACT_APP_UPLOAD_IMAGE_QUALITY)
  }


  const removeIcons = (ix) => {
    console.log(`removeIcons ${ix}`)
    const { mimeType, source } = mediaIcons[ix]
    if (source) {
      setConfirmDeleteSource(mediaIcons[ix])
    } else {
      switch (mimeType) {
        case 'image/png':
        case 'image/jpeg':
          dispatch(setSongImage(null))
          break
        case 'audio/mpeg':
          dispatch(setSongMp3(null))
          break
        /*
      case 'video/mp4':
        setMp4File(null)
        break
        */
        default: break
      }
      mediaIcons.splice(ix, 1)
      dispatch(setSongMediaIcons([...mediaIcons]))
    }
  }

  const dropped = (filesReceived) => {
    console.log('filesReceived', filesReceived)
    if (filesReceived && filesReceived.length && filesReceived[0]) {
      let file = filesReceived[0]

      setError('')
      console.log('mediadrop file', file)
      const { type: mimeType, name } = file
      const hasAudio = MEDIA_TYPES.AUDIO.includes(mimeType)
      const hasImage = MEDIA_TYPES.IMAGE.includes(mimeType)
      if (hasImage) {
        const imageSize = getUploadImageSize()
        const imageQuality = getUploadImageQuality()
        resize(file, imageSize, imageSize, imageQuality, async (result) => {
          dispatch(setSongImage(result))
          setShowUpload(false)
          const { dataUrl } = result
          dispatch(setSongMediaIcons([...mediaIcons, { index: mediaIcons.length, name, dataUrl, mimeType, file }]))
        })
      } else if (hasAudio) {
        console.log('setSongMp3', file)
        dispatch(setSongMp3({ file }))
        dispatch(setSongMediaIcons([...mediaIcons, { index: mediaIcons.length, name, mimeType, file }]))
      }
      /*
    case 'video/mp4':
      setMp4File(file)
      dispatch(setSongMediaIcons([...mediaIcons, { index: mediaIcons.length, name, mimeType, file }])
      setMediaToUpload(true)
      setShowUpload(false)
      break
      */
    } else {
      displayError('No file selected', setError, 2)
    }

  }


  const displayDropzone = () => {
    const hasAudio = hasRequiredAudio()
    const hasImage = hasRequiredImage()
    console.log(`displayDropzone hasAudio ${hasAudio} genre ${genre !== undefined} hasImage ${hasImage}`)
    if (showUpload) {

      const mimeTypes = hasAudio ? MEDIA_TYPES.IMAGE : UPLOAD_MEDIA_TYPES.AUDIO
      if (!hasAudio || (hasAudio && genre && !hasImage)) {

        return (
          <div style={{ color: 'black', padding: '1em' }}>

            <MediaDrop
              mimeTypes={mimeTypes}
              prompt={`To select your ${hasAudio ? 'image' : 'WAV or MP3'}, drag and drop or click here`}
              onSelected={(files) => dropped(files)}
              onError={setError}
              maxFileSize={parseInt(process.env.REACT_APP_MAX_HARMONIZE_UPLOAD_BYTES)}
              multiple={false}
              mediaIcons={mediaIcons}
              dropWidth='100%'
            />
          </div>
        )
      }
    }
  }

  const displaySupportDropzone = () => {
    if (showUpload) {

      const mimeTypes = MEDIA_TYPES.IMAGE
      const hasImage = hasMediaType(MEDIA_TYPES.IMAGE)
      if (!hasImage) {

        return (
          <div style={{ color: 'black', padding: '1em' }}>

            <MediaDrop
              mimeTypes={mimeTypes}
              prompt={`To select your image, drag and drop or click here`}
              onSelected={(files) => dropped(files)}
              onError={setError}
              maxFileSize={parseInt(process.env.REACT_APP_MAX_HARMONIZE_UPLOAD_BYTES)}
              multiple={false}
              mediaIcons={mediaIcons}
              dropWidth='100%'
            />
          </div>
        )
      }
    }
  }

  const displayLyrics = () => {
    if (showAddLyrics) {
      return (
        <div style={{ padding: '1em', border: '1px solid black' }}>
          <TextField
            value={lyrics}
            onChange={(event) => {
              const text = event.target.value
              dispatch(setSongLyrics(text))
              setLyricsLength(text.length)
            }}
            fullWidth
            multiline
            label={`Lyrics (${lyricsLength} of ${process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH} characters)`}
            inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH }}
          />
          <Button
            color="info"
            title='Close lyrics'
            style={{ cursor: 'pointer', display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}
            onClick={() => setShowAddLyrics(false)}>
            Close
          </Button>
        </div>
      )
    }
  }


  const doSendMessage = async (status, allMediaSchemas) => {
    console.log(`doSendMessage status ${status}`, allMediaSchemas)
    let messageToSend = await formatMessage()
    if (isEditing) {
      messageToSend.messageId = messageToEdit._id
    }
    await send(messageToSend, allMediaSchemas, isEditing, status)
  }

  /*
  const calendarActions = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          color="primary"
          onClick={() => { setShowCalendar(false) }}>
          Save
        </Button>
        <Button
          color="info"
          onClick={() => { setShowCalendar(false) }}>
          Cancel
        </Button>
      </div>
    )
  }
   
  const calendarPrompt = () => {
    return (
      <div>
        <Calendar onChange={(val) => { console.log(`calendar ${val}`) }} value={new Date()} />
      </div>
    )
  }
   
  const publishPrompt = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          color="primary"
          onClick={() => { sendMessage(1) }}>
          Now
        </Button>
        <Button
          color="info"
          onClick={() => { setShowPublishPrompt(false); setShowCalendar(true) }}>
          Later
        </Button>
      </div>
    )
  }
  */


  /** This should not send mediaIcons if they have not been updated */
  const sendMessage = async (status) => {
    console.log('sendMessage', mp3)
    if (mp3 || image) {
      let receivedMediaSchema = []
      let mediaToSend = []
      if (mp3) {
        console.log('sendMessage mp3', mp3)
        mediaToSend.push(mp3.file)
        console.log('mediaToSend', mediaToSend)
      }
      if (image) {
        mediaToSend.push(image.file)
        console.log('mediaToSend after image', mediaToSend)
      }
      console.log(`sendMessage mp3 ${mp3 ? true : false} image ${image ? true : false}`, mediaToSend)
      for (let ix = 0; ix < mediaToSend.length; ix++) {
        const fileToUpload = mediaToSend[ix]
        await upload(fileToUpload, receivedMediaSchema, ix + 1 === mediaToSend.length, status)
      }
    } else {
      await doSendMessage(status)
    }
  }

  /**
   * The Room name is the root folder  for the media either stored in
   * Backblaze or (for video) on the Core Service. First send the media, then send the message.
   */

  /**
   * 
   * @param {*} fileToUpload A File object
   * @param {*} allMediaSchemas An array of mediaSchema received from the service after successful upload
   * @param {*} isLast          True if this is the last File to upload
   * @param {*} status          The Message status.
   */
  const upload = async (fileToUpload, allMediaSchemas, isLast, status) => {
    try {
      const { name } = currentRoom
      let messageId
      if (isEditing) {
        const { _id } = messageToEdit
        messageId = _id
      }
      setSending(true)
      const { type } = fileToUpload
      const mediaSchema = await uploadMedia(fileToUpload, { name, username, messageId }, accessToken, UPLOAD_MEDIA_TYPES.IMAGE.includes(type))
      if (mediaSchema) {
        console.log('Received mediaSchema', mediaSchema)
        if (Array.isArray(mediaSchema)) {
          allMediaSchemas.push(...mediaSchema)
        } else {
          allMediaSchemas.push(mediaSchema)
        }
        if (isLast) {
          await doSendMessage(status, allMediaSchemas)
        }
      } else {
        displayError({ message: `Upload of ${fileToUpload.name} failed` }, setError, 8)
        setSending(false)
      }
    } catch (error) {
      setSending(false)
      displayError({ message: `Upload of ${fileToUpload.name} failed` }, setError, 3)
    }
  }

  /**
   * Parses the message for embedded URLS that support required og tags. Then if
   * there is any embedContent, validates and creates an embed instance. If there lyrics that is sent as document.
   * @returns {message,embed,attachment,name,embeds,summary}
   */
  const formatMessage = async () => {
    let embed, attachment
    const parsedMessage = await parseMessageForUrl(description, accessToken)
    if (embedContent.length) {
      console.log('embedContent', embedContent)
      embed = createValidEmbed(embedContent)
    }
    if (lyricsLength) {
      attachment = createLyrics(lyrics)
      console.log(`formatMessage attachment`, attachment)
    }
    const embeds = messageToEdit && messageToEdit.embeds ? messageToEdit.embeds : null
    return { message: parsedMessage, embed, attachment, name: title, embeds, summary, firstName, lastName, handle }
  }

  /**
   * When updating there may be both embedContent (the iframe) and an array of embeds the latter of which may contain
   * an existing version of the embedContent. If there is an existing version update it if the content has changed.
   * If there is no existing version then push it into the embeds array.
   *
   * The comparisons are on embedType.
   * @param {*} embed
   * @param {*} embeds
   * @returns Updated embeds
   */
  const addOrUpdateEmbed = (embed, embeds) => {
    const existingEmbed = embeds.find((e) => e.embedType === embed.embedType)
    if (existingEmbed) {
      if (existingEmbed.embedContent !== embed.embedContent) {
        const ix = embeds.findIndex((e) => e.embedType === embed.embedType)
        embeds.splice(ix, 1, embed)
      }
    } else {
      embeds.push(embed)
    }
    return embeds
  }

  /**
  * This is sent to the Social Service, where the Message expects both the roomId (for connection) and the roomName
  * for use as the media root folder.
  * 
  * If this is created in the Support category, a new Message has its approval set to APPROVAL_STATUS.APPROVED.
  *
  * The roomId sent with the message is the actual _id of the Room and not the associated Asset id.
  * 
  * @todo There should be a success return message so that the songReducer can be reset.
  * 
  * @param {*} messageBlock {message,embed,attachment,name} or {message,messageId,name}
  * @param {*} newMedia An optional array of mediaSchema that defines already-uploaded files
  * @param {*} isUpdate If true updating an existing Message
  * @param {*} status  If true and isUpdate is false then the new Message is created with the specified status. Default is 1=PUBLISHED
  */
  const send = async (messageBlock, newMedia, isUpdate, status = MESSAGE_STATUS.PUBLISHED) => {
    console.log(`send isUpdate ${isUpdate} status ${status}`, messageBlock)
    setSending(true)
    try {
      const channelParts = currentChannel.split('/')
      const { name: roomName, _id: roomId } = currentRoom
      const { category } = currentAsset
      const { message, messageId, embed, attachment, name, embeds, summary, firstName, lastName } =
        messageBlock
      let messageToSend
      if (isUpdate) {
        //Note that old Messages may not have media so this will crash on the push. Fix in DB
        const { _id, media } = messages.find(m => m._id === messageId)
        if (newMedia) {
          media.push(...newMedia)
        }
        messageToSend = { messageId: _id, message, name, media, status, summary, genre }
      } else {
        messageToSend = {
          message,
          summary,
          name,
          category,
          userId,
          username,
          media: newMedia,
          roomName,
          roomId,
          status,
          genre,
          firstName,
          lastName,
          channel: channelParts[1],
        }
        if (status === MESSAGE_STATUS.PUBLISHED) {
          messageToSend.publishedAt = new Date()
        }
        if (isSupport(currentRoom)) {
          messageToSend.approval = APPROVAL_STATUS.APPROVED
        }
      }
      if (embeds) {
        messageToSend.embeds = embeds
      }
      if (embed) {
        if (messageToSend.embeds) {
          messageToSend.embeds = addOrUpdateEmbed(embed, embeds)
        } else {
          messageToSend.embeds = [embed]
        }
      }
      if (attachment) {
        messageToSend.attachments = [attachment]
      }
      console.log(`send`, messageToSend)
      sendToSocket(messageToSend)
      closePost(true)
    } catch (error) {
      setSending(false)
      displayError(error, setError, 4)
    }
  }
  const displayAddLyrics = () => {
    if (title) {
      return (
        <div style={{ cursor: 'pointer' }} onClick={() => { setShowAddLyrics(!showAddLyrics) }} title='Lyrics' >
          <PostAddTwoTone style={{ fontSize: '3em', cursor: 'pointer', display: 'flex', marginLeft: 'auto', marginRight: 'auto' }} />
        </div>
      )
    }
  }
  /**
   * If the mediaElement exists in media, then if deleteVideo is true,
   * delete the HLS files on the Core Service. For all cases, send a deleteMessageMedia
   * socket message. When that succeeds it will send an updateMessage back that is then
   * used to update the current message.
   * 
   * If the mediaIcon is an image, then we iterate all Message media to delete the display
   * and thumbnail and any other version of the image.
   * @param {*} mediaIcon 
   * @param {*} deleteVideo 
   */
  const deleteMediaElement = async (mediaIcon, deleteVideo) => {
    const { source } = mediaIcon
    console.log(`deleteMediaElement ${source}`)
    const { media } = messageToEdit
    const mediaIndex = media.findIndex(m => {
      return m && m.source === source
    })
    if (mediaIndex !== -1) {
      try {
        if (mediaIcon.mimeType === 'image/jpeg') {
          media.map((md, ix) => {
            if (md.mimeType === 'image/jpeg') {
              console.log(`...image at index ${ix}`)
              safeEmit('deleteMessageMedia', { mediaIndex: ix, messageId, accessToken })
            }
          })
        } else {
          safeEmit('deleteMessageMedia', { mediaIndex, messageId, accessToken })
        }
        //const updatedMessage = await deleteMessageMedia(_id, mediaIndex, accessToken)
        if (deleteVideo) {
          const { source } = media[mediaIndex]
          await deleteMessageHLS(source, accessToken)
        }

      } catch (error) {
        displayError(error, setError, 5)
      }
    }
    setConfirmDeleteSource(null)
  }

  const canUpdate = () => {
    return isSupport(currentRoom) || canSave() || (isEditing && (mp3 !== null || image !== null || (title && genre)))
  }

  /** For unclear reasons just testing for mp3 (without the comparison to null) fails */
  const canSave = () => {
    const saveable = isSupport(currentRoom) ? title && description : !isEditing && hasRequiredAudio() && title && genre
    console.log(`canSave ${saveable} genre ${genre}`)
    return saveable
  }

  const isComplete = () => {
    return hasRequiredMedia() && title && genre
  }

  const editDescription = () => {
    if (isSupport(currentRoom)) {
      return <Editor value={description} onChange={(event) => {
        dispatch(setSongDescription(event.target.value))
        setDescriptionLength(event.target.value.length)
      }} />
    } else {
      return (
        <TextField
          value={description}
          onChange={(event) => {
            dispatch(setSongDescription(event.target.value))
            setDescriptionLength(event.target.value.length)
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              parseMessageForUrl(description, accessToken)
            }
          }}
          fullWidth
          multiline
          label={`Description (${descriptionLength} of ${process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH} characters)`}
          inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH }}
        />
      )
    }
  }
  const displayForm = () => {

    if (isSupport(currentRoom) || (hasRequiredAudio() && genre)) {
      return (
        <>
          <TextField
            value={title}
            onChange={(event) => {
              dispatch(setSongTitle(event.target.value))
              setTitleLength(event.target.value.length)
            }}
            fullWidth
            label={`Title [required] (${titleLength} of ${process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_NAME_LENGTH})`}
            inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_NAME_LENGTH }}
          />
          {editDescription()}
          <TextField
            value={summary}
            onChange={(event) => {
              dispatch(setSongSummary(event.target.value))
              setSummaryLength(event.target.value.length)
            }}

            fullWidth
            multiline
            label={`Summary (${summaryLength} of ${process.env.REACT_APP_MAX_HARMONIZE_SUMMARY_LENGTH} characters)`}
            inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_SUMMARY_LENGTH }}
          />

          {displayAddLyrics()}
        </>
      )
    }

  }

  const displayActions = () => {
    const { name: roomName, accessType } = currentRoom
    const supportPost = isSupport(currentRoom)
    const { status, approval } = messageToEdit
    const canPublish = accessType === ACCESS_TYPES.PRIVATE && status === MESSAGE_STATUS.DRAFT && !mp3 && !image
    let publishLabel = 'Publish'
    let publishTitle = `Publish to ${roomName}`
    if (isEditing) {

      switch (approval) {
        case APPROVAL_STATUS.PENDING:
          return (
            <Button
              color="info"
              title='Pending approval'
              style={{ cursor: 'default' }}
            >
              Pending
            </Button>
          )
        default:
        case APPROVAL_STATUS.DECLINED:
          publishLabel = 'Republish'
          publishTitle = `Not approved. Please update your content, then click Republish`
        case APPROVAL_STATUS.NONE:
          return (
            <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
              {canPublish && isComplete() ? <Button
                color="rose"
                title={publishTitle}
                style={buttonStyles.action}
                onClick={() => setConfirmPublish(approval === APPROVAL_STATUS.NONE ? 1 : 2)}>
                {publishLabel}
              </Button> : null}
              {isSupport(currentRoom) ?
                <Button
                  color="success"
                  style={{ cursor: 'pointer' }}
                  title={`Update post in ${roomName}`}
                  onClick={() => sendMessage(MESSAGE_STATUS.PUBLISHED)}>
                  Update
                </Button> :
                <Button disabled={!canUpdate()}
                  color="success"
                  style={{ cursor: 'pointer' }}
                  title={`Update draft in ${roomName}`}
                  onClick={() => (mp3 !== null || image !== null) ? setConfirmRights(true) : sendMessage(0)}>
                  Update
                </Button>
              }
            </div>
          )

      }
    } else {
      return (
        <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <Button
            color="primary"
            title='Clear this form'
            style={buttonStyles.action}
            onClick={() => clearSong()}>
            Clear
          </Button>
          {isSupport(currentRoom) ?
            <Button disabled={!canSave()}
              color="success"
              style={{ cursor: 'pointer' }}
              title={`Save post in ${roomName}`}
              onClick={() => sendMessage(MESSAGE_STATUS.PUBLISHED)}>
              Save
            </Button> :

            <Button disabled={!canUpdate()}
              color="success"
              style={{ cursor: 'pointer' }}
              title={`Save draft in ${roomName}`}
              onClick={() => (mp3 !== null || image !== null) ? setConfirmRights(true) : sendMessage(0)}>
              Save
            </Button>}
        </div>
      )
    }
  }

  /**
   * For studio category Music:
   * 
   * Only songs in the private room can be published. Songs can only be created or edited in the private room.
   * Once published, a song can be set back to Unpublished (DRAFT) from a non-private room.
   * 
   * For studio category Support:
   * 
   * Only the support user (currently info@harmonize.social) can edit, and there is no publication approval required.
   * 
   * @returns 
   */
  const displayTextAndActions = () => {
    if (!isEditing || messageToEdit) {
      return (
        <>
          {displayForm()}
          <div >
            <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
              <Button
                color="info"
                title='Go back'
                style={buttonStyles.action}
                onClick={() => closePost(true)}>
                Close
              </Button>
              {displayActions()}


            </div>

          </div>
          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', marginTop: '1em', paddingBottom: '5em' }}>
            {isEditing ? <div title='Click to delete post'><DeleteForeverTwoTone
              color='error'
              style={{ fontSize: '3em', cursor: 'pointer' }}
              onClick={(evt) => {
                evt.stopPropagation()
                setConfirmDelete(!confirmDelete)
              }}
            /> </div> : null}
            <div
              title=
              'Click to use AI'
              style={aiChatStyles.button}
              onClick={(evt) => {
                evt.stopPropagation()
                setShowAi(!showAi)
              }}
            >
              <img
                src={roundBrain}
                alt=' '
                style={{
                  height: '3em',
                }}
              />

            </div>
            {/*
              <div style={{ cursor: 'pointer' }} onClick={() => { setShowEmbed(!showEmbed) }} title='Embed' >
              <img src={embed} alt='' />
          </div>*/}

          </div>

        </>

      )
    }
  }

  const getImageIcons = () => {
    return mediaIcons
  }

  const displayGenre = () => {
    if (hasMediaType(MEDIA_TYPES.AUDIO)) {
      return <Genres />
    }
  }

  const displayLyricsOrMain = () => {
    const { name: roomName } = currentRoom
    if (showAi) {
      return (
        <><div style={{ justifyContent: 'right', width: '100%', display: 'flex' }} title='Close AI'>
          <HighlightOffTwoTone style={{ fontSize: '2em' }} onClick={() => setShowAi(false)} />
        </div>
          <div style={{ backgroundColor: 'white', height: '70vh' }}>
            <AIChat room={currentRoom} chatHeight='70vh' roomId={currentRoom._id} ownerOnly messageId={messageId} /> </div>
        </>)

    } else
      if (!isSupport(currentRoom) && isEditing && messageToEdit.status === MESSAGE_STATUS.PUBLISHED) {
        return (
          <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
            <Button
              color="info"
              title='Go back'
              style={{ cursor: 'pointer' }}
              onClick={() => closePost()}>
              Close
            </Button>
            <Button
              color="rose"
              title={`Move to ${roomName}`}
              style={{ cursor: 'pointer' }}
              onClick={async () => setConfirmMove(true)}>
              Move
            </Button>
            <Button
              color="rose"
              title={`Unpublish from ${roomName}`}
              style={{ cursor: 'pointer' }}
              onClick={async () => setConfirmUnpublish(true)}>
              Unpublish
            </Button>
          </div>
        )
      } else if (showAddLyrics) {
        return displayLyrics()
      } else {
        return (
          <>
            <div>
              <MediaIcons mediaIcons={() => getImageIcons()} imageClicked={removeIcons} />
            </div>
            {isSupport(currentRoom) ? displaySupportDropzone() : displayDropzone()}
            {displayGenre()}
            {displayTextAndActions()}
          </>
        )
      }
  }
  const displayStatus = () => {
    if (isEditing) {
      const { approval, approvalReason } = messageToEdit
      switch (approval) {
        case APPROVAL_STATUS.DECLINED:
          return <div style={{ justifyContent: 'center', display: 'grid' }}><span style={{ justifyContent: 'center', color: 'red', display: 'flex' }}>Approval declined. {DENIAL_REASONS[approvalReason]}
          </span>
            <Link to='/help/Approvals'>Click here for more information</Link>
          </div>
      }
    }
  }

  const displayMain = () => {
    const { accessType } = currentRoom
    return (
      <div style={{ marginRight: '1em', marginLeft: '1em' }}>
        <h4 style={{ textAlign: 'center' }}>{title ? `Edit ${title}` : 'Create Song'}</h4>
        {displayStatus()}
        <ErrorLine error={error} />
        {displayLyricsOrMain()}

        <ModalDialog
          title='Confirm Delete'
          message={`Are you sure you want to permanently delete ${title}?`}
          close={() => setConfirmDelete(false)}
          open={confirmDelete}
          noLabel='No'
          no={() => setConfirmDelete(false)}
          yesLabel='Yes'
          yes={async () => {
            setConfirmDelete(false)
            safeEmit('deleteMessage', { messageId, accessToken })
            close()
          }}
        />
        <ModalDialog
          title='Confirm Delete Media'
          message={`Are you sure you want to permanently delete this ${confirmDeleteSource && confirmDeleteSource.mimeType === 'audio/mpeg' ? 'audio' : 'image'}?`}
          close={() => setConfirmDeleteSource()}
          open={confirmDeleteSource ? true : false}
          noLabel='No'
          no={() => setConfirmDeleteSource()}
          yesLabel='Yes'
          yes={async () => {
            await deleteMediaElement(confirmDeleteSource)
          }}
        />
        <ModalDialog
          title='Confirm Publish'
          message={`Are you sure you want to ${confirmPublish === 1 ? 'publish' : 'republish'} ${title}?`}
          close={() => setConfirmPublish(0)}
          open={confirmPublish !== 0}
          noLabel='No'
          no={() => setConfirmPublish(0)}
          yesLabel='Yes'
          yes={() => {
            publish()
          }} />
        <ModalDialog
          title='Confirm Unpublish'
          message={`Are you sure you want to unpublish ${title}?`}
          close={() => setConfirmUnpublish(false)}
          open={confirmUnpublish}
          noLabel='No'
          no={() => setConfirmUnpublish(false)}
          yesLabel='Yes'
          yes={() => {
            setConfirmUnpublish(false)
            unpublish()
          }}
        />
        <ModalDialog
          title='Confirm Move'
          message={`Are you sure you want to move ${title} to your ${accessType === ACCESS_TYPES.PROTECTED ? 'public' : 'home'} studio?`}
          close={() => setConfirmMove(false)}
          open={confirmMove}
          noLabel='No'
          no={() => setConfirmMove(false)}
          yesLabel='Yes'
          yes={() => {
            moveToStudio()
          }}
        />
        <ModalDialog
          title='Confirm Ownership'
          content={() => displayConfirmRights()}
          close={() => setConfirmRights(false)}
          open={confirmRights}
          noLabel='No'
          no={() => { setConfirmRights(false) }}
          yesLabel='I Confirm'
          yes={() => {
            setConfirmRights(false)
            sendMessage(0)
          }}
        />
      </div>
    )
  }

  /**
   * 
   * @param {*} mediaType A MEDIA_TYPE
   * @returns 
   */
  const hasMediaType = (mediaType) => {
    for (const entry of mediaIcons) {
      const { mimeType } = entry
      if (mediaType.includes(mimeType)) {
        return true
      }
    } return false
  }

  const hasRequiredAudio = () => {
    return hasMediaType(MEDIA_TYPES.AUDIO)
  }

  const hasRequiredImage = () => {
    return hasMediaType(MEDIA_TYPES.IMAGE)
  }

  const hasRequiredMedia = () => {
    return hasMediaType(MEDIA_TYPES.AUDIO) && hasMediaType(MEDIA_TYPES.IMAGE)
  }

  /**
   * Create the icons that are displayed as MediaIcons and that determine what kind of
   * media is stored in this Message. Note that this is only for display purposes --
   * it does not change the underlying mediaSchema stored with the Message.
   * 
   * Since there (will eventually be) at least two images -- display and thumbnail -- this
   * only returns the thumbnail for icon display purposes. It strips the "t_" from the front of
   * the sourceName, again for display purposes only.
   * @param {*} media 
   */
  const setMediaIcons = (media) => {
    console.log(`setMediaIcons`, media)
    if (media) {
      let icons = []
      dispatch(setSongMediaIcons([]))
      const mediaImage = getMediaThumbnail(media)
      if (mediaImage) {
        icons.push(mediaImage)
      }
      const audioIcon = getMediaAudio(media)
      if (audioIcon) {
        icons.push(audioIcon)
      }
      /*
      media.forEach(m => {
        const mediaImage = getMediaThumbnail(m)
        if (mediaImage) {
          icons.push(mediaImage)
        }

        if (m) {
          const { mimeType, imageType, source, sourceName } = m
          if (!imageType || imageType === 'thumbnail') {
            const sourceDisplayName = imageType === 'thumbnail' ? sourceName.replace('t_', '') : sourceName
            icons.push({ index: mediaIcons.length, name: sourceDisplayName, source, mimeType, imageType })
          }
        }
        */

      console.log('...icons', icons)
      dispatch(setSongMediaIcons(icons))
    }

  }

  /**
   * Move this post either to the PUBLIC or the HOME studio.
   */
  const moveToStudio = () => {
    const { name } = currentRoom
    const moveToPublic = name.toLowerCase() === 'home'
    console.log(`moveToStudio public ${moveToPublic}`)
    const target = studios.find(s => moveToPublic ? s.name.toLowerCase() === 'public' : s.name.toLowerCase() === 'home')
    const { _id: roomId, name: roomName } = target
    let updatedMessage = {
      ...messageToEdit, messageId: messageToEdit._id, roomId, roomName
    }
    console.log(`moveToStudio public ${moveToPublic}`, updatedMessage)
    setConfirmMove(false)

    sendToSocket(updatedMessage)
    closePost(true)
    history.replace(moveToPublic ? `/@${handle}/public` : `/@${handle}/home`)

  }

  /**
   * When attempting to publish,   Client should check approval
    DECLINED sets Publish button back to Draft
    PENDING sets Publish button to Pending
   * @param {*} target  The _id and name of the Studio to which this Message should be published 
   * @param {*} status  MESSAGE_STATUS
   * @param {*} republish If true set APPROVAL_STATUS.NONE
   */
  const pubUnpub = (target, status, republish) => {
    const { _id: roomId, name: roomName } = target
    let updatedMessage = {
      ...messageToEdit, messageId: messageToEdit._id, status, roomId, roomName, approvalTarget: { roomId, roomName }
    }
    if (republish) {
      updatedMessage.approval = APPROVAL_STATUS.NONE
    }
    console.log(`pubUnpub ${status} ${republish}`, updatedMessage)
    sendToSocket(updatedMessage)
    closePost(true)
  }

  const publish = () => {
    console.log(`publish ${confirmPublish}`)
    const homeStudio = studios.find(s => s.accessType === ACCESS_TYPES.PROTECTED)
    if (homeStudio) {
      pubUnpub(homeStudio, MESSAGE_STATUS.PUBLISHED, confirmPublish === 2)
    }
    setConfirmPublish(0)
  }

  const unpublish = () => {
    const privateStudio = studios.find(s => s.accessType === ACCESS_TYPES.PRIVATE)
    if (privateStudio) {
      pubUnpub(privateStudio, MESSAGE_STATUS.DRAFT)
      history.replace(`/@${handle}/private`)
    }
  }

  useEffect(() => {
    addArtistStudios(accessToken).then(s => { console.log('Received studios', s); setStudios(s.studios); setShowUpload(true) }).catch(error => displayError(error, setError, 6))
  }, [accessToken])
  useEffect(() => {
    if (isEditing) {
      const messageToEdit = messages.find(m => m._id === messageId)
      if (!messageToEdit) {
        console.error(`Unable to find message ${messageId} in messages`, messages)
        displayError(`Unable to find message ${messageId} in messages`, setError, 7)
      } else {
        console.log('messageToEdit', messageToEdit)
        setMessageToEdit(messageToEdit)
        const { message, name, attachments, embeds, media, summary, genre } = messageToEdit
        dispatch(setSongImage(null))
        dispatch(setSongMp3(null))
        dispatch(setSongDescription(message))
        dispatch(setSongGenre(genre))
        setTitleLength(name ? name.length : 0)
        setDescriptionLength(message ? message.length : 0)
        setSummaryLength(summary ? summary.length : 0)
        dispatch(setSongTitle(name))
        dispatch(setSongSummary(summary))
        if (attachments && attachments.length) {
          dispatch(setSongLyrics(attachments[0].attachmentContent))
          setLyricsLength(attachments[0].attachmentContent.length)
        } else {
          dispatch(setSongLyrics(''))
          setLyricsLength(0)
        }
        if (embeds && embeds.length) {
          setEmbedContent(embeds[0].embedContent)
        }
        if (media && media.length) {
          setMediaIcons(media)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, messages])
  return (
    <div >
      <HarmonizeHeader />
      {sending ? <TimerProgress label='Processing. Please wait and do *not* leave this screen' /> : displayMain()}
      <Footer />
    </div>
  )
}
