
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
//The following did not work: We had to take the scss from the Github lib and include it in our scss files.
//import 'react-h5-audio-player/lib/styles.css'
import { setPlayerMode, setQueuePlay, setPlayListIndex } from 'redux/actions/harmonizeActions'
import { PLAYER_MODES } from 'redux/reducers/harmonizeReducer'
import {
    Add, Apps, Close, InsertEmoticon, Favorite, FavoriteBorderTwoTone,
    List, ListAlt, MusicNote, OndemandVideo, PersonAdd, PersonOutline, QueueMusic, Shuffle,
    SkipNext, SkipPrevious, TrackChanges, Chat, DataUsage, HelpOutline,
    FavoriteBorder, Lock, LockOpen, Public, Cancel, Fullscreen, Share
} from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'

import { useState, createRef } from 'react'
import UserImage from 'components/image/UserImage'
import imagesStyles from 'assets/jss/material-kit-react/imagesStyles'
import { primaryColor } from 'assets/jss/material-kit-react.js'
import { useHistory } from 'react-router-dom'
import { isVeryNarrow } from 'util/screenUtils'
import { Snackbar, SnackbarContent, useMediaQuery } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.js'
import { displayHomeIcon } from 'util/brandUtils'
import { updateSubscribed } from 'controllers/AccountController'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { logWithTime } from 'util/screenUtils'
import { displayError } from 'util/screenUtils'
import { setAccount } from 'redux/actions/accountActions'
import { getStorageAccount } from 'redux/actions/accountActions'
import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom'
import { canLikePost } from 'util/transactionUtils'
import { addLike } from 'util/socialServiceUtils'
import { deleteLike } from 'util/socialServiceUtils'
import { useEffect } from 'react'
import { downloadCoreFile } from 'controllers/BackblazeController'
import ErrorLine from 'components/ErrorLine'
import { setPlayTime } from 'redux/actions/harmonizeActions'
import { addEngagement } from 'controllers/HarmonizeController'
import { ENGAGEMENT_TYPES } from 'controllers/HarmonizeController'
import { canRecordEngagement } from 'util/transactionUtils'
import { setEngaged } from 'redux/actions/harmonizeActions'
import ShareContent from 'components/harmonize/ShareContent'
import BlurDialog from 'components/utility/BlurDialog'
import { setAutoPlay } from 'redux/actions/harmonizeActions'
import useRefsCollection from 'react-refs-collection'
import EmojiPicker from 'emoji-picker-react'
import SmallProfileImage from 'components/auction/SmallProfileImage'
import moment from 'moment'
import { setReactorPlay } from 'redux/actions/harmonizeActions'
import { addReaction } from 'controllers/HarmonizeController'
import { getReactions } from 'controllers/HarmonizeController'
import { setShowChat } from 'redux/actions/harmonizeActions'
import { setCreditPlayTime } from 'redux/actions/harmonizeActions'
import { setAccruedCredits } from 'redux/actions/harmonizeActions'
import { applyCredit } from 'controllers/HarmonizeController'
import Explain from 'components/Explain'
import { getAvailableCredits } from 'controllers/AccountController'
import Footer from 'components/Footer/Footer'
import { setHideOverlay } from 'redux/actions/harmonizeActions'
import { COLLECTION_TYPE } from 'util/postUtils'
import { isGooglebot } from 'util/screenUtils'
import { chargeCreditToStudio } from 'controllers/HarmonizeController'
import { checkCreditUsage } from 'util/creditUtils'

export const REACTOR_REACTIONS = ['1f44d', '2764-fe0f', '1f603', '1f483', '1f57a', '1f3b8', '1f3b9', '1f941']

export const CREDIT_TYPES = {
    AUDIO: 0,
    VIDEO: 1,
    PUBLIC_AUDIO: 2
}
let elapsedPlayTime, currentPlayTime

/** We use user instead of userId because that is what the Social Service db uses. */
export default function HarmonizePlayer({ roomOwner,
    setCollectionPlaylist, shuffle, messageSocket, enterFullScreen }) {
    const veryNarrow = isVeryNarrow(useMediaQuery)
    const videoWidth = veryNarrow ? '240px' : '480px'
    const playIconTop = (veryNarrow ? 120 * .56 : 240 * .56) - 20

    const playerStyles = {
        chatIcon: {
            fontSize: veryNarrow ? '1.5em' : '2em'
        },
        controlIcon: {
            fontSize: veryNarrow ? '1.5em' : '2em',
            marginRight: veryNarrow ? '0.25em' : '1em'
        },
        fullscreen: { color: 'gold', fontWeight: 'bolder', WebkitTextStroke: '0.05px black' },
        randomControlIcon: {
            fontSize: veryNarrow ? '1em' : '1.5em',
            marginRight: veryNarrow ? '0.25em' : '.5em'
        },
        likeIcon: {
            fontSize: veryNarrow ? '1.5em' : '2em',
        },
        helpLine: {
            display: 'flex', alignItems: 'center', fontSize: veryNarrow ? '0.9em' : 'inherit', borderBottom: '0.5px dashed black'
        },
        shareIcon: {
            fontSize: veryNarrow ? '1em' : '1.5em',
            marginRight: veryNarrow ? '0.25em' : '1em'
        },
    }
    const ACCRUE_CREDIT_TIME = parseInt(process.env.REACT_APP_ACCRUE_CREDIT_TIME)
    const USE_CREDIT_TIME = parseInt(process.env.REACT_APP_USE_CREDIT_TIME)
    const accessToken = getStorageAccessToken()
    const account = getStorageAccount()
    const user = account ? account.id : undefined
    const { account: ephemeralAccount } = useSelector(state => state.account)
    const subscribedRooms = ephemeralAccount ? ephemeralAccount.subscribedRooms : null
    const { accruedCredits, accruedCreditsRoom, autoPlay, collections, creditPlayTime,
        currentCollection, engaged, fullscreen, hideOverlay, playerMode, playList, playListIndex, playTime, queuePlay, reactorPlay, showChat } = useSelector(state => state.harmonize)
    const { currentRoom, messages } = useSelector(state => state.messages)
    const { accessType } = currentRoom
    const isPrivateRoom = accessType === ACCESS_TYPES.PRIVATE
    const [error, setError] = useState()
    const [paused, setPaused] = useState(false)
    const [playStart, setPlayStart] = useState(null)
    const [accumulatedPlayTime, setAccumulatedPlayTime] = useState(0)
    const [showCollections, setShowCollections] = useState(false)
    const [showShare, setShowShare] = useState(false)
    const [showShareCollection, setShowShareCollection] = useState(false)
    const [copiedUrl, setCopiedUrl] = useState()
    const [outOfCredits, setOutOfCredits] = useState(false)
    const [timeOverlay, setTimeOverlay] = useState(false)
    const [imageSrc, setImageSrc] = useState()
    const [randomPlay, setRandomPlay] = useState(false)
    const [randomPlayList, setRandomPlayList] = useState([])
    const [randomPlayStart, setRandomPlayStart] = useState(0)
    const [receivedReaction, setReceivedReaction] = useState()
    const [reactorLines, setReactorLines] = useState([])
    const [helpTarget, setHelpTarget] = useState()
    const [explain, setExplain] = useState()
    const { getRefHandler, getRef } = useRefsCollection()
    const dispatch = useDispatch()
    const history = useHistory()
    const player = createRef()

    const displayIntro = () => {
        const { name: roomName } = currentRoom
        return (
            <div onClick={() => setExplain(null)}>
                <p style={playerStyles.helpLine}>
                    <span style={{ fontWeight: 'bold', color: 'gold', backgroundColor: 'blue', margin: '0.25em' }}>Playlists</span>&nbsp;Displays all Playlists for this Studio
                </p>
                <p style={playerStyles.helpLine}>
                    <PersonAdd style={{ fontSize: '2em' }} />&nbsp;Click this to receive an email notification whenever a new song is posted.
                </p>
                <p style={playerStyles.helpLine}>
                    <DataUsage style={{ fontSize: '2em' }} />&nbsp;Displays your current streaming credits. Click to buy more. If you
                    don't see this, you are not charged for streaming in the {roomName} studio.
                </p>
                <p style={playerStyles.helpLine}>
                    <Shuffle style={{ fontSize: '2em' }} />&nbsp;Shuffles the current playlist
                </p>
                <p style={playerStyles.helpLine}>
                    <Fullscreen style={{ fontSize: '2em' }} />&nbsp;Switch to a full screen song player
                </p>
                <p style={playerStyles.helpLine}>
                    <ListAlt style={{ fontSize: '2em' }} />&nbsp;Switch to compact song listings
                </p>
                <p style={playerStyles.helpLine}>
                    <List style={{ fontSize: '2em' }} />&nbsp;Switch to full song listings
                </p>
                <p style={playerStyles.helpLine}>
                    <Apps style={{ fontSize: '2em' }} />&nbsp;Switch to a song grid
                </p>
                <div style={playerStyles.helpLine}>
                    <div>
                        <QueueMusic style={{ fontSize: '2em' }} />&nbsp;<MusicNote style={{ fontSize: '2em' }} /></div>
                    Switch between continuous play and single play
                </div>
                <p style={playerStyles.helpLine}>
                    <FavoriteBorder style={{ fontSize: '2em' }} />&nbsp;Add the current song to your favorites
                </p>
                <p style={playerStyles.helpLine}>
                    <TrackChanges style={{ fontSize: '2em' }} />&nbsp;Play {process.env.REACT_APP_AUDIO_RANDOM_PLAY_LENGTH} seconds each from a random selection in the current playlist
                </p>
                <p style={playerStyles.helpLine}>
                    <InsertEmoticon style={{ fontSize: '2em' }} />&nbsp;Open the Reactor to record your reactions to the current song
                </p>
                <p style={playerStyles.helpLine}>
                    <Chat style={{ fontSize: '2em' }} />&nbsp;Chat with other listeners in the {roomName} studio
                </p>
            </div>
        )
    }
    const explainPlayer = {
        contentFunction: displayIntro
    }

    const message = () => {
        const msg = messages.find(m => m._id === playList[playListIndex].messageId)
        if (msg) {
            //console.log(`messageId at ${playListIndex} ${msg._id}`)
            return msg
        } else {
            return { numLikes: 0, liked: false }
        }
    }

    /**
     * 
     * @returns true if there is no accessToken and the Room access is PUBLIC
     */
    const isAnonymousAccess = () => {
        const anonymous = !accessToken && accessType === ACCESS_TYPES.PUBLIC
        return anonymous
    }

    const addLikeToMessage = async () => {
        const { name } = currentRoom
        if (isAnonymousAccess()) {
            history.push(`/SignIn/${name}`)
        } else {
            try {
                const { _id: messageId, userId } = message()
                if (canLikePost(user, userId, isAnonymousAccess())) {

                    try {
                        addLike(messageId, user, messageSocket())
                    } catch (error) {
                        console.error('error adding like', error)
                        displayError(error, setError, 1)
                    }
                }

            } catch (error) {
                displayError(error, setError, 2)
            }
        }
    }
    const deleteLikeFromMessage = () => {
        const { _id: messageId, userId } = message()
        if (canLikePost(user, userId, isAnonymousAccess())) {
            try {
                deleteLike(messageId, user, messageSocket())
            } catch (error) {
                console.error('error deleting like', error)
                displayError(error, setError, 3)
            }
        }
    }

    const displayShareSong = () => {
        return (
            <div title='Click to share' onClick={(evt) => {
                evt.stopPropagation()
                setShowShare(!showShare)
            }}>
                <Share style={playerStyles.shareIcon} />
            </div>
        )
    }

    const displayLikes = () => {
        const { numLikes, liked } = message()
        return (
            <div style={{ display: 'flex', paddingRight: numLikes > 0 ? '10px' : '0', alignItems: 'center' }}>
                {liked ?
                    <Favorite style={playerStyles.likeIcon}
                        onClick={() => deleteLikeFromMessage()} />
                    :
                    <FavoriteBorderTwoTone style={{ ...playerStyles.likeIcon, ...{ marginRight: 0 } }}
                        onClick={() => addLikeToMessage()} />
                }
                {numLikes > 0 ? `${numLikes}` : null}
            </div>
        )

    }

    const displayCredits = () => {
        if (accessType !== ACCESS_TYPES.PUBLIC && ephemeralAccount) {
            const { availableCredits } = ephemeralAccount
            return (
                <div
                    onClick={() => history.push('/BuyCredits')}
                    style={{
                        cursor: 'pointer',
                        zIndex: 200,
                        position: 'relative'
                    }}>
                    <DataUsage

                        style={{
                            color: availableCredits <= 5 ? 'red' : 'gold',

                            fontSize: '3em'
                        }} />
                    <span
                        title='Credits'
                        style={{
                            zIndex: 201, position: 'absolute', fontSize: '0.75em', top: '1.5em',
                            left: 0, color: 'white', width: '100%', textAlign: 'center'
                        }}>{Intl.NumberFormat({ maximumFractionDigits: 0, useGrouping: false }).format(availableCredits)}</span>
                </div>
            )
        }
    }

    const displayModeSelector = () => {
        switch (playerMode) {
            default:
            case PLAYER_MODES.COMPACT:
                return (
                    <div style={{ display: 'flex' }}>

                        <div title='Switch to a song grid' style={{ cursor: 'pointer' }}>
                            <Apps style={{ ...playerStyles.controlIcon, ...{ marginRight: 0 } }} onClick={() => dispatch(setPlayerMode(PLAYER_MODES.GRID))} /></div>
                    </div>
                )

            case PLAYER_MODES.POST:
                return (
                    <div style={{ display: 'flex' }}>

                        <div title='Switch to compact listings' style={{ cursor: 'pointer' }}>
                            <ListAlt style={{ ...playerStyles.controlIcon, ...{ marginRight: 0 } }} onClick={() => dispatch(setPlayerMode(PLAYER_MODES.COMPACT))} /></div>
                    </div>
                )
            case PLAYER_MODES.GRID:
                return (
                    <div style={{ display: 'flex' }}>

                        <div title='Switch to full listings' style={{ cursor: 'pointer' }}>
                            <List style={{ ...playerStyles.controlIcon, ...{ marginRight: 0 } }} onClick={() => dispatch(setPlayerMode(PLAYER_MODES.POST))} /></div>
                    </div>
                )
        }

    }

    const displayAdditionalControls = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div title='Shuffle' style={{ cursor: 'pointer' }}>
                    <Shuffle style={playerStyles.controlIcon} onClick={() => shuffle()} /></div>
                {displayModeSelector()}
            </div>
        )
    }

    const displayCustomVolumeControls = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>

                {displayShareSong()}
                {displayLikes()}
            </div>
        )
    }
    /**
     * @returns 
     */
    const displayQueuePlay = () => {
        if (queuePlay) {
            return (
                <div title='Click for Single Play' style={{ display: 'flex', cursor: 'pointer' }}>
                    <QueueMusic style={playerStyles.controlIcon} onClick={() => dispatch(setQueuePlay(false))} /></div>)

        } else {
            return (
                <div title='Click for Continuous Play' style={{ display: 'flex', cursor: 'pointer' }}>
                    <MusicNote style={playerStyles.controlIcon} onClick={() => {
                        dispatch(setQueuePlay(true))
                        dispatch(setAutoPlay(true))
                    }} />
                </div>)
        }

    }
    const displayCopied = () => {
        if (copiedUrl) {
            return (
                <Snackbar
                    open={copiedUrl}
                    autoHideDuration={2000}
                    onClose={() => setCopiedUrl(false)}
                    message='Copied'
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                >

                </Snackbar>
            )
        }
    }


    const displayShare = () => {
        if (showShare && !isGooglebot(window.navigator.userAgent)) {
            const { name, messageId } = playList[playListIndex]
            return (
                <div onClick={(evt) => {
                    evt.stopPropagation()
                    setShowShare(false)
                }}>
                    <BlurDialog content={() => <ShareContent messageId={messageId}
                        name={name}
                        setCopied={setCopiedUrl} />} hideHelp title={`Share ${name}`} closeIcon />
                </div>
            )
        }
    }

    const displayShareCollection = () => {
        if (showShareCollection && currentCollection) {
            const { name, _id: collectionId } = currentCollection
            const { name: roomName } = currentRoom
            return (
                <div onClick={(evt) => {
                    evt.stopPropagation()
                    setShowShareCollection(false)
                }}>
                    <BlurDialog content={() => <ShareContent name={name} collectionId={collectionId} roomName={roomName}
                        setCopied={setCopiedUrl} />} hideHelp title={`Share the ${name} collection`} closeIcon top='0px' />
                </div>
            )
        }
    }

    const subscribeRoom = async (subscribe) => {
        if (accessToken) {
            const { _id } = currentRoom
            try {
                const updatedUser = await updateSubscribed(_id, subscribe ? 1 : 0, accessToken)
                logWithTime('subscribeRoom Received updated user', updatedUser)
                const updatedAccount = { ...updatedUser }
                dispatch(setAccount(updatedAccount))
            } catch (error) {
                displayError(error, setError, 4)
            }
        } else {
            const { name } = currentRoom
            history.push(`/SignIn/${name}`)
        }
    }

    const displaySubscribed = () => {
        const username = account ? account.username : undefined
        const { friendlyName } = currentRoom
        if (username !== roomOwner.username) {
            let subscribed
            if (subscribedRooms && subscribedRooms.length) {

                const { _id } = currentRoom
                subscribed = subscribedRooms.findIndex(r => r.roomId === _id) !== -1
            }
            return (
                <div
                    onClick={() => subscribeRoom(!subscribed)}
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        width: 40,
                        height: 40,
                        borderRadius: 20,
                        border: '0.5px solid white',
                        backgroundColor: 'blue',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    title={`Click to ${subscribed ? 'unsubscribe from' : 'subscribe to'} to ${friendlyName}`}
                >
                    {subscribed ? <PersonOutline style={{
                        height: '30px',
                        width: '30px'
                    }} /> :
                        <PersonAdd
                            style={{
                                height: '30px',
                                width: '30px'
                            }}
                        />}
                </div>
            )
        }
    }

    const listCollections = () => {
        return (
            <div style={{ padding: '0.5em' }}>
                {collections.map(c =>
                    <div key={c.name} style={{ cursor: 'pointer' }} onClick={async () => {
                        setCollectionPlaylist(c)
                    }}><b>{c.name}</b>: {c.description}</div>
                )}
            </div>
        )
    }

    const displayCollections = () => {
        if (showCollections) {
            return (<div onClick={(evt) => {
                evt.stopPropagation()
                setShowCollections(false)
            }}>
                <BlurDialog content={listCollections} hideHelp title='Playlists' closeIcon animationTime={200} />
            </div>)
        }
    }

    const displayCollectionsButton = () => {
        if (accessType !== ACCESS_TYPES.PUBLIC && collections.length)
            return (
                <div title='Click to show Playlists'
                    style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', }}
                    onClick={() => setShowCollections(!showCollections)}><span style={{
                        border: '1px solid gold',
                        padding: '0.2em',
                        fontWeight: 'bold', borderRadius: '4px'
                    }}>Playlists</span></div>
            )
    }
    const displayAccessType = () => {
        switch (accessType) {
            default:
            case ACCESS_TYPES.PRIVATE:
                return <div title='Private'><Lock /></div>
            case ACCESS_TYPES.PROTECTED:
                return <div title='Protected'><LockOpen /></div>
            case ACCESS_TYPES.PUBLIC:
                return <div title='Public'><Public /></div>
        }

    }

    const displayFullScreenHeader = () => {
        if (fullscreen) {
            const { firstName, lastName, name } = playList[playListIndex]
            return (
                <div style={{
                    position: 'fixed', top: 0, width: '100%', padding: '1.5em', fontSize: veryNarrow ? '1.5em' : '2em', justifyContent: 'space-between', fontWeight: 'bold', color: 'white',
                    backgroundColor: 'transparent',
                    display: hideOverlay ? 'none' : 'flex'
                }}>
                    <span style={playerStyles.fullscreen}>{firstName} {lastName}</span>
                    <span style={playerStyles.fullscreen}>{name}</span>
                </div>
            )
        }
    }

    const displayHeader = (entry) => {
        const { img, providerImgUrl, firstName, lastName, username } = roomOwner
        const { name: roomName } = currentRoom
        if (!fullscreen) {

            return (
                <div style={{ display: 'flex' }}>
                    {displayHomeIcon(history)}
                    <div style={{ display: 'flex', position: 'absolute', left: 0, width: '100%', justifyContent: 'center' }}>

                        <UserImage
                            title={`${roomOwner.firstName}`}
                            clickTitle={`Click to display ${firstName} ${lastName}`}
                            image={{ img, providerImgUrl }}
                            style={imagesStyles.vortexProfileImageContainer}
                            click={() => {

                                history.push(`/UserProfile/${username}`)
                            }}

                        />
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div style={{ fontSize: '0.75em', display: 'flex', alignItems: 'center' }}>{firstName}&nbsp;{lastName}&nbsp;{displayAccessType()}&nbsp;{roomName}</div>
                            {entry ? <span style={{ fontWeight: 'bold', fontSize: entry.name.length > 24 ? '0.75em' : '1em' }}>{entry.name}</span> : null}
                        </div>

                    </div>
                    {displaySubscribed()}
                </div>
            )
        }
    }

    const displayOutOfCredits = () => {
        if (outOfCredits) {
            let message, clickMessage, target
            if (accessType === ACCESS_TYPES.PUBLIC) {
                message = 'There are no more streaming credits available in this Studio'
                clickMessage = 'Click to go home'
                target = '/'
            } else {
                message = 'Hi! You are out of streaming credits. Please buy a few on the next screen.'
                clickMessage = 'Click to buy credits'
                target = '/BuyCredits'
            }
            return (
                <Snackbar
                    open={outOfCredits}
                    autoHideDuration={5000}
                    onClose={() => {
                        setOutOfCredits(false)
                        history.push(target)
                    }}
                    anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                >
                    <SnackbarContent
                        style={{ backgroundColor: 'blue', border: '1px solid white' }}
                        message={message}
                        action={<div style={{ cursor: 'pointer', color: 'gold' }} onClick={() => {
                            setOutOfCredits(false)
                            history.push(target)
                        }}>
                            <i>{clickMessage}</i>
                        </div>} />
                </Snackbar>
            )

        }
    }

    const recordEngagement = async (entry) => {
        elapsedPlayTime = Math.round((Date.now() - playStart) / 1000)
        if (randomPlay) {
            if (elapsedPlayTime >= parseInt(process.env.REACT_APP_AUDIO_RANDOM_PLAY_LENGTH)) {
                playNext()
            }
        } else {
            const { duration } = entry
            //console.log(`recordEngagement duration ${duration} engaged ${engaged}`)
            if (duration && !engaged) {
                const totalPlayTime = Math.round((Date.now() - playStart) / 1000)
                const newPlayTime = accumulatedPlayTime + totalPlayTime
                dispatch(setPlayTime(newPlayTime))
                const percentPlayed = duration ? Math.round(newPlayTime / duration * 100) : 0
                //console.log(`playTime ${newPlayTime} percentPlayed ${percentPlayed} `)

                const { _id: messageId, userId } = message()
                try {
                    const minPercent = parseInt(process.env.REACT_APP_AUDIO_ENGAGEMENT_PERCENT)
                    if (percentPlayed >= minPercent && canRecordEngagement(user, userId, isAnonymousAccess())) {
                        console.log(`Engagement after ${percentPlayed} ${newPlayTime}`)
                        dispatch(setEngaged(true))
                        await addEngagement(ENGAGEMENT_TYPES.AUDIO, messageId, user, newPlayTime)
                    }
                } catch (error) {
                    console.error(`Unable to add engagement for ${messageId}`, error)
                }
            }
        }
    }

    const displayEmptyCollection = () => {
        const { name } = currentCollection
        return (
            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <div style={{ cursor: 'pointer' }} title={`Click to close ${name}`}
                    onClick={(evt) => {
                        evt.stopPropagation()
                        setCollectionPlaylist()
                    }}><Cancel style={{ fontSize: '1.5em' }} />
                </div>
                {name} is empty.<br />Select a different Playlist, or click the close button.
            </div>
        )
    }

    const checkCredits = async () => {
        try {
            const currentCredits = await checkCreditUsage(accruedCredits, accruedCreditsRoom, creditPlayTime, currentRoom, getPlaylistEntry(),
                ephemeralAccount, accessToken, dispatch)
            if (currentCredits < 1) {
                setOutOfCredits(true)
            }
        } catch (error) {
            displayError(error, setError, 5)
            //If applyCredit FAILS we still have to reset the accruedCredits array or we blow up the server
            //with a huge array. There should only be max 6 entries (with a 1 minute server update interval and a 10 second accruedCredit interval)
            dispatch(setCreditPlayTime(1))
            dispatch(setAccruedCredits([]))
        }
    }

    /**
     * If this is a PRIVATE studio, then it is possible to have an undefined audio source so that messages can be
     * created; otherwise, no audio source means display just a header.
     * 
     * @See https://lhz516.github.io/react-h5-audio-player/?path=/docs/layouts-advanced--stacked
     * @returns 
     */
    const displayPlayer = () => {

        const entry = getMediaEntry('audio/mpeg')
        if (entry) {
            const { source, name } = entry

            return (
                <AudioPlayer
                    ref={player}
                    header={displayHeader(entry)}
                    footer={displayAudioFooter(entry)}
                    autoPlayAfterSrcChange={autoPlay}
                    progressJumpSteps={{
                        forward: 10000,
                        backward: 10000
                    }}
                    src={source}
                    showJumpControls={source !== undefined}
                    customAdditionalControls={
                        playList.length && !fullscreen ?
                            [displayAdditionalControls()] : []
                    }
                    customProgressBarSection={
                        hideOverlay ? [] : [

                            RHAP_UI.CURRENT_TIME,
                            RHAP_UI.PROGRESS_BAR,
                            RHAP_UI.CURRENT_LEFT_TIME,
                            displayCredits()
                        ]
                    }
                    customVolumeControls={source !== undefined && !fullscreen ? [
                        displayCustomVolumeControls()] : []}
                    showFilledVolume
                    showSkipControls={true}
                    onPause={() => setPaused(true)}
                    onPlay={() => {
                        currentPlayTime = Math.round(player.current.audio.current.currentTime)
                        console.log(`onPlay ${playListIndex} paused ${paused} time ${currentPlayTime}`)
                        setError('')
                        if (paused) {
                            setAccumulatedPlayTime(playTime)
                            setPaused(false)
                        } else {
                            if (randomPlay) {
                                player.current.audio.current.currentTime = randomPlayStart
                            } else if (accessToken) {
                                const handle = account ? account.handle : undefined
                                const { messageId } = getPlaylistEntry()
                                console.log(`onPlay send playing ${messageId}`)
                                safeEmit('playing', { handle, messageId, name })
                            }
                            setPaused(false)
                            setPlayStart(Date.now())
                            setAccumulatedPlayTime(0)
                            dispatch(setPlayTime(0))
                            dispatch(setEngaged(false))
                            if (fullscreen) {
                                dispatch(setHideOverlay(false))
                                setTimeOverlay(true)
                            }
                        }
                    }
                    }
                    onClickNext={() => playNext()}
                    onEnded={() => queuePlay ? playNext() : setPlayStart(null)}
                    onClickPrevious={() => playPrevious()}
                    onListen={() => {
                        if (player.current && player.current.audio) {
                            checkCredits()
                            currentPlayTime = Math.round(player.current.audio.current.currentTime)
                            if (playStart) {
                                recordEngagement(entry)
                            }
                        }
                    }}
                    /* We are ignoring these errors because the tend to occur when the page is reloaded
                       and they have no real conseuqnce we think */
                    onError={(error) => {
                        console.error('Error playing audio', error)
                        //setError(`Unable to play this audio`)
                    }}
                    onPlayError={(error) => {
                        console.error('PlayError playing audio', error)
                        //setError(`Unable to play this audio (play error)`)
                    }}
                    style={{
                        backgroundColor: fullscreen ? 'transparent' : primaryColor,
                        color: 'gold'
                    }}
                />

            )

        }
    }

    const displayPlayVideo = () => {
        const fontSize = veryNarrow ? '2em' : '3em'
        return (
            <div style={{ position: 'relative', marginLeft: 'auto', marginRight: 'auto' }}
            >
                {imageSrc ? (<div><img
                    alt=''
                    src={imageSrc}
                    style={{ width: videoWidth }} />
                    <OndemandVideo style={{ fontSize, position: 'absolute', top: playIconTop, left: 0, width: videoWidth }} /></div>)
                    : <OndemandVideo style={{ fontSize }} />}
            </div>
        )


    }

    const displayCollectionHeader = () => {
        const { _id } = currentRoom
        if (accessType !== ACCESS_TYPES.PUBLIC && currentCollection && currentCollection.roomId === _id) {
            const { name, collectionType } = currentCollection
            if (collectionType !== COLLECTION_TYPE.STUDIO) {
                return (
                    <div style={{
                        backgroundColor: 'white', alignItems: 'center', display: 'flex', color: 'black', justifyContent: 'center', border: '1px solid gold',
                        padding: '0.2em',
                        marginTop: '0.5em',
                        position: 'relative'
                    }}>
                        <div style={{ cursor: 'pointer', position: 'absolute', left: 0 }} title={`Click to close ${name}`}
                            onClick={(evt) => {
                                evt.stopPropagation()
                                setCollectionPlaylist()
                            }}><Close />
                        </div>
                        {currentCollection.name}
                        {/*<div style={{ cursor: 'pointer', position: 'absolute', right: 10 }} title={`Click to share ${name}`}
                        onClick={(evt) => {
                            evt.stopPropagation()
                            setShowShareCollection(true)
                        }}><Share />
                    </div>*/}
                    </div>
                )
            }
        }
    }

    const checkAccess = (perform) => {
        if (accessToken) {
            perform()
        } else {
            const { name } = currentRoom
            history.push(`/SignIn/@${name}`)
        }
    }

    const displayRandomAndCollections = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div title='Help' style={{ cursor: 'pointer' }}>
                        <HelpOutline style={playerStyles.controlIcon} onClick={(event) => {
                            setHelpTarget(event.currentTarget)
                            setExplain(explainPlayer)
                        }} />
                    </div>
                    <div title='Sampler' style={{ cursor: 'pointer' }}>
                        <TrackChanges style={playerStyles.controlIcon} onClick={() => {
                            if (randomPlay) { resetRandomPlay() }
                            else { createRandomPlayList() }
                        }} />
                    </div>
                    <div title='Switch to fullscreen' style={{ cursor: 'pointer' }}>
                        <Fullscreen style={playerStyles.controlIcon} onClick={() => {
                            enterFullScreen()
                        }} /></div>
                </div>
                {displayCollectionsButton()}
                {!isPrivateRoom ? <div style={{ display: 'flex', alignItems: 'center' }}>
                    {displayQueuePlay()}
                    <div title='Reactor' style={{ cursor: 'pointer' }}>
                        <InsertEmoticon style={playerStyles.controlIcon} onClick={() => checkAccess(() => startOrStopReactor(reactorPlay))} />
                    </div>
                    <div title='Chat' style={{ cursor: 'pointer' }}>
                        <Chat style={playerStyles.chatIcon} title='Chat'
                            onClick={() => checkAccess(() => {
                                dispatch(setShowChat(!showChat))
                            }
                            )
                            } />
                    </div>

                </div> : null}
            </div>
        )
    }
    const displayAudioFooter = (entry) => {
        if (!fullscreen) {
            const { source } = entry
            if (source) {
                return (
                    <div>
                        {displayRandomAndCollections()}
                        {displayCollectionHeader()}
                        {displayVideoPlayer()}
                    </div>
                )
            } else {
                return (
                    <div>
                        {displayCollectionsButton()}</div>
                )
            }
        }
    }
    /**
     * This function creates an HREF to the VideoPlayer page. That page then uses the encrypted URL in the mediaEntry
     * to read the video from the Core Service.
     * 
     * The imageMediaSource is used to create the thumbnailUrl for structured data in VideoPlayer. It must be obtained from the same
     * Message that contains the video/mp4 media entry. There must always be an image in any Message that has a video.
     * @returns 
     */
    const displayVideoPlayer = () => {
        const entry = getMediaEntry('video/mp4')
        //console.log(`displayVideoPlayer ${entry.sourceName}`, entry)
        if (entry && entry.sourceName) {
            const imageEntry = getMediaEntry('image/jpeg')
            const mediaSource = imageEntry ? imageEntry.mediaSource : undefined
            const { name } = entry

            const url = `/videoplayer`
            return (
                <a href={url} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <div title={`Click to play ${name} video`}
                        style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', flexDirection: 'column', textAlign: 'center' }}
                    >
                        {displayPlayVideo(mediaSource)}
                        <span style={{ fontSize: '.9em', fontStyle: 'italic' }}>Click to play {name} video</span>
                    </div>
                </a>
            )


        }
    }

    const getPlaylistEntry = () => {
        if (randomPlayList.length) {
            return randomPlayList[playListIndex]
        } else if (playList.length) {
            return playList[playListIndex]
        } else if (isPrivateRoom && messages.length) {
            const { _id: messageId, name, userId } = messages[playListIndex]
            return { entryMedia: [{ name, messageId, userId, source: undefined, mimeType: 'audio/mpeg' }] }
        } else {
            return { entryMedia: [{ name: 'No song', messageId: 0, userId: '', mimeType: undefined }] }
        }
    }

    /**
     * Get the media element for the current playList entry that matches the
     * requested mimeType
     * @param {*} mt
                        * @returns The entry or if not found a dummy {source: undefined, name: '--' }
                        */
    const getMediaEntry = (mt) => {
        const { entryMedia } = getPlaylistEntry()
        for (const entry of entryMedia) {
            const { mimeType } = entry
            if (mimeType === mt) {
                return entry
            }
        }
        return { source: undefined, name: '--' }
    }


    const getReactionsForEntry = (entry) => {
        if (reactorPlay) {
            const { messageId } = entry
            populateReactions(messageId)
        }
    }
    const playNext = () => {
        if (playListIndex < playList.length - 1) {
            const entry = playList[playListIndex + 1]
            dispatch(setPlayListIndex(playListIndex + 1))
            dispatch(setPlayTime(0))
            setPlayStart(Date.now())
            getReactionsForEntry(entry)
            if (fullscreen) {
                dispatch(setHideOverlay(false))
                setTimeOverlay(true)
            }
        } else {
            setPlayStart(null)
        }
    }

    const playPrevious = () => {
        if (playListIndex > 0) {
            const entry = playList[playListIndex - 1]
            dispatch(setPlayListIndex(playListIndex - 1))
            dispatch(setPlayTime(0))
            setPlayStart(Date.now())
            getReactionsForEntry(entry)
            if (fullscreen) {
                dispatch(setHideOverlay(false))
                setTimeOverlay(true)
            }
        } else {
            setPlayStart(null)
        }
    }

    /**
     * Get Reactions for this Message, then start reactorPlay. If Reactions cannot be retrieved
     * reactorPlay does not start.
     */
    const populateReactions = async (messageId) => {
        try {
            const reactorReactions = await getReactions(messageId, accessToken)
            console.log(`reactions for ${messageId}`, reactorReactions)
            const rLines = []
            reactorReactions.forEach(r => {
                const { reactions, playTime } = r
                rLines.push({ reactorTime: playTime, reactions })
            })
            setReactorLines([...rLines])
            dispatch(setReactorPlay(true))
        } catch (error) {
            displayError(error, setError, 6)
        }
    }

    const safeEmit = (type, message) => {
        if (messageSocket()) {
            messageSocket().emit(type, message)
        }
    }

    const startOrStopReactor = (stop) => {
        console.log(`reactorPlay ${stop}`)
        if (stop) {
            setReactorLines([])
            dispatch(setReactorPlay(false))
        } else if (accessToken) {
            elapsedPlayTime = 0
            player.current.audio.current.currentTime = 0
            const { _id: messageId } = message()
            populateReactions(messageId)
            player.current.audio.current.play()
        } else {
            const { name } = currentRoom
            history.push(`/SignIn/@${name}`)
        }
    }

    const displayReactor = () => {
        //console.log(`displayReactor ${reactorPlay}`)
        if (reactorPlay) {
            return displayReactorLines()
        }
    }

    /**
     * Take the received emojiObject of {unifiedEmoji, user, playTime} and add a reaction
                        * to the reactions array in reactorLines at currentPlayTime. The completed reactorLine
                        * for a given playTime is then {reactorTime, reactions}. If sendToServer is true this is then posted to the server for the
                        * current messageId.
                        *
                        * The caller must ensure the Reaction added is targeted for the current messageId.
                        *
                        * The Reaction added to the Social Service db does not need user, since it is taken from the signed-in User.
                        * When posted to the messageSocket, user is required so that when it is received at other session
                        * listeners, the proper User profile image is displayed.
                        *
                        * @param {*} emojiObject {unifiedEmoji, user, playTime} where user is the ID of the reacting User
                        */
    const addMediaReaction = async (reaction, sendToServer = true) => {
        if (accessToken) {
            console.log(`addMediaReaction sendToServer ${sendToServer}`, reaction)
            console.log('...current reactorLines', reactorLines)
            const { playTime, unifiedEmoji, user } = reaction
            const reactorLineIx = reactorLines.findIndex(r => r.reactorTime === playTime)
            if (reactorLineIx !== -1) {
                const reactorLine = reactorLines[reactorLineIx]
                console.log(`Received reaction at playTime ${playTime} ${reactorLine.reactorTime}`, reactorLine)
                reactorLine.reactions.push({ user, unifiedEmoji })
                reactorLines.splice(reactorLineIx, 1, reactorLine)
                setReactorLines([...reactorLines])
            } else {
                const reactions = [{ user, unifiedEmoji }]
                reactorLines.push({ reactorTime: playTime, reactions })
                reactorLines.sort((a, b) => {
                    return a.reactorTime < b.reactorTime ? -1 : a.reactorTime > b.reactorTime ? 1 : 0
                })
                setReactorLines([...reactorLines])
            }
            console.log(reactorLines)
            if (sendToServer) {
                try {
                    const { _id: messageId } = message()
                    const reaction = { messageId, unifiedEmoji, playTime }
                    await addReaction(reaction, accessToken)
                    logWithTime('added reaction to Social Service')
                    safeEmit('reaction', { ...reaction, user })
                    logWithTime('emitted to Social Service')
                } catch (error) {
                    displayError(error, setError, 7)
                }
            }

        } else {
            const { name } = currentRoom
            history.push(`/SignIn/@${name}`)
        }
    }

    const [showReactions, setShowReactions] = useState(true)

    const createReaction = (emojiObject) => {
        const { unified: unifiedEmoji } = emojiObject
        return { unifiedEmoji, playTime: currentPlayTime, user }
    }

    /** Don't allow adding reactions unless playing */
    const displayReactionsOrPicker = () => {
        if (playStart) {
            if (showReactions) {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <EmojiPicker reactionsDefaultOpen={true} allowExpandReactions={false}
                            reactions={REACTOR_REACTIONS}
                            onReactionClick={(eo) => { addMediaReaction(createReaction(eo)); setShowReactions(true) }}
                        />
                        <Add style={playerStyles.controlIcon} onClick={() => { setShowReactions(false) }} />
                    </div>)
            } else {
                return (
                    <div >
                        <Close style={playerStyles.controlIcon} onClick={() => { setShowReactions(true) }} />
                        <EmojiPicker
                            onEmojiClick={(eo) => { addMediaReaction(createReaction(eo)); setShowReactions(true) }}
                        /></div>)
            }
        }
    }

    /**
     * It appears that the unifiedEmoji may comprise multiple hyphen-separated values. To solve this see:
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/fromCodePoint
     * and
     * https://www.bennadel.com/blog/4084-rending-emoji-glyphs-using-hexadecimal-codepoints-in-javascript.htm
     * @param {*} reaction
                        * @param {*} ix
                        * @returns
                        */
    const displayReaction = (reaction, ix) => {
        const { unifiedEmoji, user } = reaction
        const actualEmoji = unifiedEmoji.split('-')
        const codePoints = []
        actualEmoji.forEach(e => {
            codePoints.push(parseInt(e, 16))
        })
        try {
            return (
                <div key={ix} style={{ display: 'flex', alignItems: 'center', overflowX: 'auto' }}>
                    <SmallProfileImage userId={user} />
                    {String.fromCodePoint.apply(null, codePoints)}
                </div>
            )
        } catch (error) {
            console.error(`Bad ${unifiedEmoji}`, error)
        }

    }
    const displayReactorLine = (l) => {
        const { reactorTime, reactions } = l
        const displayDuration = moment.duration(reactorTime, 'seconds')
        const backgroundColor = reactorTime === currentPlayTime ? 'gold' : 'white'
        return (
            <div key={reactorTime} style={{ display: 'flex', paddingLeft: '0.5em', backgroundColor, alignItems: 'center' }}
                onClick={(evt) => { evt.stopPropagation(); player.current.audio.current.currentTime = reactorTime }}>
                {displayDuration.format(reactorTime >= 60 ? 'm:ss' : '0:ss')} {reactions.map((r, ix) => displayReaction(r, ix))}
            </div>
        )
    }
    const displayReactorLines = () => {
        return (
            <div >
                {displayReactionsOrPicker()}
                {reactorLines.map(l => displayReactorLine(l))}
            </div>
        )
    }

    const createRandomPlayList = () => {
        // eslint-disable-next-line
        const newPlayList = playList.reduce(([a, b]) => (b.push(...a.splice(Math.random() * a.length | 0, 1)), [a, b]), [[...playList], []])[1]
        console.log('randomPlayList', newPlayList)
        setRandomPlayList(newPlayList)
        setRandomPlay(true)
        dispatch(setPlayListIndex(0))
        dispatch(setAutoPlay(true))

    }

    const displayRandomPlayLine = (rp, ix) => {
        return (
            <div key={rp.messageId}
                ref={getRefHandler(rp.messageId)}
                style={{ cursor: 'pointer', backgroundColor: ix === playListIndex ? 'gold' : 'white' }}
                onClick={(evt) => {
                    evt.stopPropagation()
                    history.push(`/song/${rp.messageId}`)
                }}>
                {rp.name}
            </div>
        )
    }
    const displayRandomPlayList = () => {
        return (
            <div style={{ overflow: 'auto', height: '50vh' }} >
                {randomPlayList.map((rp, ix) => displayRandomPlayLine(rp, ix))}
            </div>
        )
    }

    const randomPlayHeader = () => {

        return (<div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', borderBottom: '1px solid gray' }}>
            <span style={{ fontStyle: 'italic' }}>Sampler</span>
            <div style={{ display: 'flex', alignItems: 'center' }}>Start at&nbsp;<Button
                color='primary' size='sm' onClick={(evt) => {
                    evt.stopPropagation()
                    setRandomPlayStart(randomPlayStart === 0 ? 30 : 0)
                }}>{randomPlayStart}</Button></div>
            <SkipPrevious style={playerStyles.controlIcon} onClick={(evt) => {
                evt.stopPropagation()
                playPrevious()
            }
            } />
            <SkipNext style={playerStyles.controlIcon} onClick={(evt) => {
                evt.stopPropagation()
                playNext()
            }
            } /></div>)

    }

    const displayRandomPlay = () => {
        if (randomPlay) {
            return (
                <div onClick={(evt) => {
                    evt.stopPropagation()
                    resetRandomPlay()
                }}>
                    <BlurDialog content={displayRandomPlayList} hideHelp titleFunc={() => randomPlayHeader()} closeIcon top='50px' />
                </div>
            )
        }
    }

    const resetRandomPlay = () => {
        setRandomPlay(false)
        setRandomPlayList([])
        dispatch(setPlayListIndex(0))
        dispatch(setAutoPlay(false))
    }

    const loadMedia = async (imageEntry) => {
        const { mediaSource } = imageEntry
        console.log(`loadMedia playlistIndex ${playListIndex} ${mediaSource}`)
        if (mediaSource) {
            try {
                const data = await downloadCoreFile(mediaSource, 'image')
                const src = URL.createObjectURL(data)
                setError('')
                return { src, mediaSource }
            } catch (error) {
                displayError(error, setError, 8)
            }
        }
        return null
    }

    const displayMain = () => {
        return (
            <div>
                <ErrorLine error={error} />
                {displayPlayer()}
                {displayFullScreenHeader()}
                {displayShare()}
                {displayCollections()}
                {displayShareCollection()}
                {displayCopied()}
                {displayRandomPlay()}
                {displayReactor()}
                {displayOutOfCredits()}


                {explain && (
                    <Explain
                        source={explain}
                        target={helpTarget}
                        close={() => setExplain(null)}
                    />
                )}
                {!fullscreen ? <Footer /> : null}
            </div>
        )
    }

    const initSession = (credits) => {
        console.log(`initSession credits ${credits}`)
        //testRsa()
        if (credits) {
            const account = getStorageAccount()
            dispatch(setAccount({ ...account, availableCredits: credits }))
        }
        startOrStopReactor(true)
        if (messageSocket() && accessToken) {
            messageSocket().on('reaction', async (reaction) => {
                setReceivedReaction(reaction)
            })

        }
    }


    useEffect(() => {
        //console.log(`HarmonizePlayer useEffect timeOverlay ${timeOverlay} hideOverlay ${hideOverlay}`)

        if (timeOverlay) {
            dispatch(setHideOverlay(false))
            setTimeOverlay(false)
            setTimeout(() => {
                //console.log('...execute timeOverlay timeout disable hideOverlay')
                dispatch(setHideOverlay(true))
            }, 5000)
        }
        /*
        return () => {
            dispatch(setHideOverlay(false))
            clearTimeout(timeoutId)
        }
        */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeOverlay])


    useEffect(() => {
        if (receivedReaction) {
            const { messageId: reactionMessageId } = receivedReaction
            logWithTime(`useEffect received reaction for ${reactionMessageId} current messageId ${getPlaylistEntry().messageId}`, receivedReaction)
            if (getPlaylistEntry().messageId === reactionMessageId) {
                addMediaReaction(receivedReaction, false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receivedReaction])


    /** Reset reactorPlay on first load of page. Connect the socket listener. */
    useEffect(() => {
        console.log(`HarmonizePlayer accessToken`, accessToken)
        if (accessType !== ACCESS_TYPES.PUBLIC && accessToken) {
            getAvailableCredits(accessToken).then(ac => ac < 1 ? setOutOfCredits(true) : initSession(ac)).catch(error => displayError(error, setError, 9))
        } else {
            initSession()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /**
     * This gets an image mediaEntry from the playList at the current playListIndex. This is so
     * that any image used in the player is correct for the current playList entry.
     */
    useEffect(() => {
        console.log(`\n *HarmonizePlayer useEffect messages ${messages.length} playListIndex ${playListIndex} playTime ${playTime}`, playList)

        if (playList.length) {
            const imageEntry = getMediaEntry('image/jpeg')
            if (imageEntry) {
                loadMedia(imageEntry).then(result => {
                    if (result) {
                        const { src } = result
                        setImageSrc(src)
                    }
                })
            }
        }
        setAccumulatedPlayTime(0)
        //setPaused(false)
        dispatch(setEngaged(false))
        const { name } = getPlaylistEntry()
        if (name && name.length) {
            document.title = name
        }

        if (randomPlay) {
            const itemNode = getRef(randomPlayList[playListIndex].messageId)
            if (itemNode) {
                itemNode.scrollIntoView(false)
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playList, playListIndex, playerMode, reactorPlay, reactorLines])

    const handleVisibilityChange = () => {
        console.log(`**> HarmonizePlayer visibilityChange ${document.visibilityState} currentState ${document.visibilityState}`)
    }
    useEffect(() => {
        console.log(`**> HarmonizePlayer useEffect add visibilityChange listener visibility ${document.visibilityState}`)
        window.addEventListener('visibilitychange', handleVisibilityChange)
        return () => {
            console.log('**> HarmonizePlayer remove visibilityChange listener')
            window.removeEventListener('visibilitychange', handleVisibilityChange)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return displayMain()
}