module.exports = {
  ACCOUNT: {
    ACCESS_TOKEN: 'accountaccesstoken',
    ARTIST: 'accountartist',
    ETH: 'accounteth',
    SET: 'accountset',
  },
  ARTIST: {
    CURRENT_ARTIST: 'artistcurrentartist',
  },
  ASSET: {
    CURRENT_ALBUM: 'assetcurrentalbum',
    CURRENT_ASSET: 'assetcurrentasset',
    CURRENT_ASSETS: 'assetcurrentassets',
    CURRENT_ISSUE: 'assetcurrentissue',
    SET: 'assetsset',
  },
  CHAT: {
    ADD: 'chatadd',
    SET: 'chatset',
    SET_CURRENT_AVAILABLE_THOUGHTS: 'chatsetcurrentavailablethoughts',
    SET_CURRENT_COLLABORATOR: 'chatsetcurrentcollaborator',
    SET_ROOM_ID: 'chatroomid',
    SET_SESSION_ID: 'chatsetsessionid',
    SET_WAITING_FOR_HOST: 'chatwaitingforhost'
  },
  DISPLAY: {
    FULLSCREEN_PLAYER: 'displayfullscreenplayer',
    SHOW_HEADER: 'displayshowheader',
    TRUEMUSIC: 'displaytruemusic',
    TRUEPLACE: 'displaytrueplace',
    TRUETRAIL: 'displaytruetrail',
    TRANSACTION_IN_PROGRESS: 'displaytransactioninprogress',
    VORTEX: 'displayvortex',
  },
  EMAIL: {
    ALBUM_NAME: 'emailalbumname',
    ASSET_ID: 'emailassetid',
  },
  HELP: {
    SHOW_AI_SIGN_IN: 'helpsetshowaisignin',
    SHOW_AI_WELCOME: 'helpshowaiwelcome',
    SHOW_SIGN_IN: 'helpshowsignin',
    SHOW_PROFILE_HELP: 'helpshowprofilehelp',
    SHOW_WELCOME: 'helpshowwelcome',
  },
  HARMONIZE: {
    SET_ACCRUED_CREDITS: 'harmonizesetaccruedcredits',
    SET_ACCRUED_CREDITS_ROOM: 'harmonizesetaccruedcreditsroom',
    SET_AUTO_PLAY: 'harmonizesetautoplay',
    SET_COLLECTIONS: 'harmonizesetcollections',
    SET_CREDIT_PLAY_TIME: 'harmonizesetcreditplaytime',
    SET_CURRENT_COLLECTION: 'harmonizesetcurrentcollection',
    SET_ENGAGED: 'harmonizesetengaged',
    SET_FULLSCREEN: 'harmonizesetfullscreen',
    SET_HIDE_OVERLAY: 'harmonizesethideoverlay',
    SET_PLAYLIST: 'harmonizesetplaylist',
    SET_PLAYLIST_INDEX: 'harmonizesetplaylistindex',
    SET_PLAYER_MODE: 'harmonizesetplayermode',
    SET_PLAY_TIME: 'harmonizesetplaytime',
    SET_QUEUE_PLAY: 'harmonizesetqueueplay',
    SET_REACTOR_PLAY: 'harmonizesetreactorplay',
    SET_SHOW_AI: 'harmonizesetshowai',
    SET_SHOW_ATTACHMENTS: 'harmonizeshowattachments',
    SET_SHOW_CHAT: 'harmonizesetshowchat'
  },
  MESSAGES: {
    ADD: 'messagesadd',
    ADD_COMMENT: 'messagesaddcomment',
    DELETE: 'messagesdelete',
    DELETE_COMMENT: 'messagesdeletecomment',
    GET: 'messagesget',
    SET: 'messagesset',
    SET_CHANNEL: 'messagessetchannel', SET_ROOM: 'messagessetroom',
    SET_SOCKET: 'messagessetsocket',
    UPDATE: 'messagesupdate',
  },
  PURCHASE: {
    SET_TRANSACTION_TYPE: 'purchasesettransactiontype',
  },
  SETTINGS: {
    CURRENCY: 'settingscurrency',
    MAP_CENTER: 'settingsmapcenter',
    MAP_TYPE_ID: 'settingsmaptypeid',
    MAP_ZOOM: 'settingszoom',
    MAINMAP: {
      CENTER: 'settingsmainmapcenter',
      TYPE_ID: 'settingsmainmaptypeid',
      ZOOM: 'settingsmainmapzoom',
    },
    SET: 'settingsset',
    SET_MAINMAP: 'settingsmainmap'
  },
  SONG: {
    DESCRIPTION: 'songdescription',
    GENRE: 'songgenre',
    IMAGE: 'songimage',
    LYRICS: 'songlyrics',
    MEDIA_ICONS: 'songmediaicons',
    MP3: 'songmp3',
    RESET: 'songreset',
    SUMMARY: 'songsummary',
    TITLE: 'songtitle'
  }
}
