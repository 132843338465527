import { CloudUploadTwoTone, PublishTwoTone } from '@material-ui/icons'
import { useRef, useState } from 'react'
import ErrorLine from 'components/ErrorLine'
import { resize } from 'util/imageUtils'
import TimerProgress from 'components/mint/TimerProgress'
import { isImageMimeType } from 'util/fileUtils'
import { createRef } from 'react'
import { useSelector } from 'react-redux'

/** This function gets multiple media objects and displays them in a ScrollMenu.
 * The default number of media files is 6.
 * 
 * If mimeTypes is a non-empty array, it limits the types of files that can be selected.
 */
export default function MediaDrop({
  onSelected,
  prompt,
  multiple,
  mimeTypes = [],
  maxFileSize,
  maxNumMedia = 6,
  dropWidth,
  uploadAction
}) {

  const { mediaIcons } = useSelector(state => state.song)
  const [error, setError] = useState()
  const [completed, setCompleted] = useState(false)
  const [loading, setLoading] = useState(false)
  const inputRef = createRef(null)

  const getImageIcon = (index, files, { file, dataUrl }) => {
    if (mediaIcons.length < maxNumMedia) {
      if (isImageMimeType(file.type)) {
        const newIcon = { index, dataUrl, name: file.name, mimeType: file.type }
        const imageIcon = mediaIcons.find((img) => img.name === file.name)
        if (imageIcon) {
          mediaIcons.splice(index, 1, newIcon)
        } else {
          mediaIcons.push(newIcon)
        }
      } else {
        mediaIcons.push({ index, mimeType: file.type, name: file.name })
      }
      if (index + 1 < files.length) {
        resizeAndSet(index + 1, files)
      } else {
        setCompleted(true)
        setLoading(false)
      }
    } else {
      setCompleted(true)
      setLoading(false)
    }
  }

  const resizeAndSet = (ix, files) => {
    if (files[ix].type === 'image/jpeg') {
      resize(files[ix], 120, 120, 0.6, async (result) =>
        getImageIcon(ix, files, result)
      )
    } else {
      getImageIcon(ix, files, { file: files[ix] })
    }
  }

  const getFiles = (fileList) => {
    setCompleted(false)
    setLoading(true)
    const selectedFiles = []
    setError()
    for (const file of fileList) {
      if (file.size > maxFileSize) {
        console.error(`${file.name} is too large ${file.size}`)
        setError(`${file.name} is too large (maximum size is ${process.env.REACT_APP_MAX_HARMONIZE_UPLOAD_BYTES / 1024} megabytes)`)
        break
      }
      const typeIx = mimeTypes.findIndex((a) => a == file.type)
      if (typeIx === -1) {
        console.error(`${file.type} is not allowed`)
        setError(`${file.type} is not allowed`)
      } else {
        selectedFiles.push(file)
      }
      if (!multiple) {
        break
      }
    }
    if (selectedFiles.length) {
      onSelected(selectedFiles)
      //mediaIcons = []
      resizeAndSet(0, selectedFiles)
    }

  }
  const ignoreDrag = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const dropped = (e) => {
    ignoreDrag(e)
    const dt = e.dataTransfer
    getFiles(dt.files)
  }
  const selected = (e) => {
    getFiles(e.target.files)
  }

  const displayUploadAction = () => {
    return (
      <div style={{ cursor: 'pointer' }} onClick={() => uploadAction()}>
        <PublishTwoTone color='primary' fontSize='large' />
        <p>Click to upload this file</p>
      </div>
    )

  }
  const displayMediaDropzone = () => {
    if (!error && (mediaIcons.length < maxNumMedia || uploadAction)) {
      //console.log(`displayMediaDropzone`, mimeTypes)
      return (
        <div>
          {mimeTypes.length ? <input
            type='file'
            id='fileDrop'
            ref={inputRef}
            multiple={multiple}
            accept={mimeTypes}
            onChange={selected}
            style={{ display: 'none' }}
          /> : <input
            type='file'
            id='fileDrop'
            ref={inputRef}
            multiple={multiple}
            onChange={selected}
            style={{ display: 'none' }}
          />}
          <div
            draggable='true'
            onDragStart={ignoreDrag}
            onDrop={dropped}
            onDragOver={ignoreDrag}
            style={{
              width: dropWidth ? dropWidth : '50vw',
              height: '12vh',
              border: '1px solid black',
              margin: 'auto',
              textAlign: 'center',
            }}
            onClick={(e) => {
              e.stopPropagation()
              inputRef.current.click()
            }}
          >
            <CloudUploadTwoTone color='primary' style={{ fontSize: '2em', cursor: 'pointer' }} />
            <p>{prompt ? prompt : `Drag and drop or click to select ${maxNumMedia > 1 ? 'files' : 'file'}`}</p>
          </div>
        </div>
      )
    }
  }
  /**
   * Note that input.accept only works as a filter on file dialog and not on what can actually
   * be received.
   * @returns
   */
  const displayDropAndFiles = () => {
    return (
      <div>
        {completed && uploadAction ? displayUploadAction() : displayMediaDropzone()}
        <ErrorLine error={error} />

        {/*<MediaIcons mediaIcons={mediaIcons} imageClicked={imageClicked} />*/}
      </div>
    )
  }
  const displayLoadOrLoading = () => {
    if (!error && loading) {
      return <TimerProgress />
    } else {
      return displayDropAndFiles()
    }
  }
  return displayLoadOrLoading()
}
