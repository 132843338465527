import { CREDIT_TYPES } from 'components/harmonize/HarmonizePlayer'
import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom'
import { applyCredit } from 'controllers/HarmonizeController'
import { chargeCreditToStudio } from 'controllers/HarmonizeController'
import { setAccount } from 'redux/actions/accountActions'
import { setAccruedCreditsRoom } from 'redux/actions/harmonizeActions'
import { setCreditPlayTime } from 'redux/actions/harmonizeActions'
import { setAccruedCredits } from 'redux/actions/harmonizeActions'
const ACCRUE_CREDIT_TIME = parseInt(process.env.REACT_APP_ACCRUE_CREDIT_TIME)
const USE_CREDIT_TIME = parseInt(process.env.REACT_APP_USE_CREDIT_TIME)

/**
 * This should be called from the AudioPlayer onListen with an interval of one second. 
 * 
 * First check to see if the current Studio is not the same as the one from the last check. If it has changed, then we
 * need to flush a Credit with the accruedCredits for that room. This may mean a partial Credit is charged. The
 * creditPlayTime is then set to 0.
 * @param {*} accruedCredits 
 * @param {*} creditPlayTime 
 * @param {*} currentRoom 
 * @param {*} playlistEntry 
 * @param {*} account 
 * @param {*} accessToken 
 * @param {*} dispatch 
 * @returns remaining credits. <1 means out of credits
 */
export const checkCreditUsage = async (accruedCredits, accruedCreditsRoom, creditPlayTime, currentRoom, playlistEntry, account, accessToken, dispatch) => {
    console.log(`...checkCreditUsage ${creditPlayTime}`, playlistEntry)
    let currentCredits = 1
    if (accruedCreditsRoom && accruedCreditsRoom._id !== currentRoom._id) {
        console.log(`...flush credits from room ${accruedCreditsRoom.name}`)
        currentCredits = await writeCreditsForRoom(accruedCreditsRoom, accruedCredits, 1, account, accessToken, dispatch)
        creditPlayTime = 0
        accruedCredits = []
        dispatch(setAccruedCredits([]))
        dispatch(setCreditPlayTime(0))
    }
    if (currentCredits >= 1) {
        if (creditPlayTime % ACCRUE_CREDIT_TIME === 0) {
            const { userId, messageId } = playlistEntry
            if (!(userId && messageId)) {
                throw new Error(`Warning: not enough information to record artist credit (${userId} ${messageId})`)
            } else if (accruedCredits.length > USE_CREDIT_TIME / ACCRUE_CREDIT_TIME) {
                throw new Error(`Warning: too many accrued credits (${userId} ${messageId})`)
            } else {
                const ix = accruedCredits.findIndex(ac => ac.artist === userId && ac.messageId === messageId)
                if (ix !== -1) {
                    accruedCredits[ix].count += 1
                    console.log(`...increase accruedCredits for ${userId} to ${accruedCredits[ix].count}`)
                } else {
                    const accruedCredit = { count: 1, artist: userId, messageId }
                    accruedCredits.push(accruedCredit)
                }
                console.log(`...accruedCredit to ${userId} for song ${messageId}`, accruedCredits)
                dispatch(setAccruedCredits([...accruedCredits]))
            }
        }
        if (creditPlayTime >= USE_CREDIT_TIME) {
            currentCredits = await recordCreditUsage(1, accruedCredits, currentRoom, account, accessToken, dispatch)
        } else {
            dispatch(setCreditPlayTime(creditPlayTime + 1))
        }
    }
    return currentCredits
}

/**
 * Write the credits associated with currentRoom, then reset playTime to 1, empty the
 * accruedCredits array, and set the accruedCreditsRoom to currentRoom.
 * 
 * @param {*} creditsUsed
 * @return currentCredits. If this is <1 the user is out of streaming credits
*/
const recordCreditUsage = async (creditsUsed, accruedCredits, currentRoom, account, accessToken, dispatch) => {
    let currentCredits = await writeCreditsForRoom(currentRoom, accruedCredits, creditsUsed, account, accessToken, dispatch)
    dispatch(setCreditPlayTime(1))
    dispatch(setAccruedCredits([]))
    dispatch(setAccruedCreditsRoom(currentRoom))
    return currentCredits
}

/**
 * Write the Credit record to the creditsRoom.
 * 
 * In a PUBLIC studio, credits are charged against the studio owner. Since that account may be accessed
 * from multiple users, get the current credits available for the studio owner before recording the new
 * charge. This is done on the Core Service.
 * 
 * @param {*} creditsUsed
 * @return currentCredits. If this is <1 the user is out of streaming credits
*/
const writeCreditsForRoom = async (creditsRoom, accruedCredits, creditsUsed, account, accessToken, dispatch) => {
    let currentCredits = 0
    const { _id: roomId, accessType } = creditsRoom
    if (accessType === ACCESS_TYPES.PUBLIC) {
        const credit = { action: CREDIT_TYPES.PUBLIC_AUDIO, roomId, accruedCredits, creditsUsed }
        const updatedCredit = await chargeCreditToStudio(credit)
        currentCredits = updatedCredit.availableCredits
    } else {
        const { availableCredits } = account
        const credit = { action: CREDIT_TYPES.AUDIO, roomId, accruedCredits, creditsUsed }
        await applyCredit(credit, accessToken)
        currentCredits = availableCredits - 1
        dispatch(setAccount({ ...account, availableCredits: currentCredits }))
    }
    return currentCredits
}