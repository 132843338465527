import './App.css'
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import Vault from './pages/Vault'
/* See https://fontawesome.com/how-to-use/on-the-web/using-with/react re the following */
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'
import SignIn from './pages/SignIn'

import EmailConfirmation from './pages/EmailConfirmation'
import MyProfile from './pages/MyProfile'

import { setCurrentArtist } from 'redux/actions/artistActions'
import Pricing from './pages/Pricing'

import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import ConfirmEmailAddress from 'pages/ConfirmEmailAddress'
import Contact from 'pages/Contact'
import Privacy from 'pages/Privacy'
import TermsOfService from 'pages/TermsOfService'
import firebase from 'firebase/compat/app'
import Settings from 'pages/Settings'
import { useEffect } from 'react'
import ManageApprovals from 'pages/admin/ManageApprovals'
import { getFirebaseConfig } from 'util/firebaseUtils'
import Credits from 'pages/Credits'
import FAQ from 'pages/help/FAQ'
import { setAccount } from 'redux/actions/accountActions'
import { setAccessToken } from 'redux/actions/accountActions'
import { setMainMap } from 'redux/actions/settingsActions'
import { getStorageMainMap } from 'redux/actions/settingsActions'
import { getStorageAccount } from 'redux/actions/accountActions'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { getStorageSettings } from 'redux/actions/settingsActions'
import { setSettings } from 'redux/actions/settingsActions'
import Studio from 'vortex/Studio'
import HarmonizeHome from 'pages/vortex/HarmonizeHome'
import PaymentResult from 'components/payment/PaymentResult'
import CreateRoom from 'pages/vortex/CreateRoom'
import { EditRoom } from 'components/vortex/EditRoom'
import About from 'pages/About'
import Approvals from 'pages/help/Approvals'
import BuyCredits from 'components/payment/BuyCredits'
import VortexSwitch from 'components/vortex/VortexSwitch'
import EmbedAI from 'pages/vortex/EmbedAI'
import ManageThoughts from 'pages/admin/ManageThought'
import PressRelease from 'pages/news/PressRelease'
import UserProfile from 'pages/UserProfile'
import Artists from 'pages/Artists'
import VideoPlayer from 'components/harmonize/VideoPlayer'
import CreateCollection from 'pages/harmonize/CreateCollection'
import Studios from 'pages/harmonize/Studios'
import { isAdminUser } from 'util/adminUtils'
import { getMessaging, onMessage } from 'firebase/messaging'
import { useState } from 'react'
import { Snackbar, SnackbarContent } from '@material-ui/core'
import AboutStudios from 'pages/help/AboutStudios'

library.add(fab, faCheckSquare, faCoffee)

//This seemed to fix the Typography references
const theme = createTheme({
  typography: {
    allVariants: {
      color: 'black',
    },
    fontSize: '0.9em',
    lineHeight: '1.5',
    fontFamily: [
      'Raleway',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
})

/* Configure Firebase. It needs to be here so that whatever is created is globally available
without hoops */
firebase.initializeApp(getFirebaseConfig())

//ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID)

export default function App({ store }) {

  const [notification, setNotification] = useState(null)
  const history = useHistory()
  const isAuth = () => {
    // console.log('isAuth', getStorageAccessToken())
    return getStorageAccessToken()
  }

  const messaging = getMessaging()
  onMessage(messaging, (payload) => {
    console.log(`Received notification`, payload)
    const { notification } = payload
    const { body, title } = notification
    setNotification({ body, title })
  })

  /**
   * There is something wrong with redux-persist or how we are using it: in production, when the browser
   * is restarted or navigated to the absolute URL, persistence is gone. When running
   * against localhost, everything works.
   *
   * But in production local storage is preserved, so we use the latter to build redux store.
   * Just be sure the Web server redirects everything to the same subdomain so that you only have
   * one localStorage instance.
   *
   * @returns The component that is rendered for the home screen
   */
  const renderHome = () => {

    const storageAccount = getStorageAccount()
    const storageAccessToken = getStorageAccessToken()
    store.dispatch(setAccount(storageAccount))
    store.dispatch(setAccessToken(storageAccessToken))
    const settings = getStorageSettings()
    if (settings) {
      store.dispatch(setSettings(settings))
    }
    const mainMap = getStorageMainMap()
    if (mainMap) {
      store.dispatch(setMainMap(mainMap))
    }

    const pathname = window.location.pathname.toLowerCase()
    console.log(`renderHome hostname ${window.location.hostname} pathname ${pathname}`)
    if (
      (pathname.startsWith('/@'))
    ) {
      console.log('Determine artist and room')
      //This means the pathname can be of form /@[handle]/[something else]
      let ixEnd = pathname.indexOf('/', 2)
      if (ixEnd === -1) {
        ixEnd = pathname.length
      }
      const userHandleLength = ixEnd - 2
      if (userHandleLength > 0) {
        const pathParts = pathname.split('/')
        console.log('patjParts', pathParts)
        const handle = pathParts[1].substring(1) //pathname.substring(2, 2 + userHandleLength)
        console.log(`handle ${handle} ${userHandleLength}`)
        let roomName, startWithPost
        if (pathParts.length > 2) {
          //There was a second part
          roomName = pathParts[2]
          console.log(`roomName ${roomName}`)
          if (pathParts.length > 3) {
            startWithPost = pathParts[3] === 'true'
          }
          store.dispatch(setCurrentArtist({ handle, useDefaultRoom: false, selectedRoom: roomName, startWithPost }))
        } else {
          store.dispatch(setCurrentArtist({ handle, useDefaultRoom: true, selectedRoom: undefined, startWithPost: false }))
        }
        return <VortexSwitch />
      }

    }
    store.dispatch(setCurrentArtist(null))
    return <HarmonizeHome />

  }

  const displayNotification = () => {
    if (notification) {
      const { body, title } = notification
      const notifyAccount = getStorageAccount()
      console.log('displayNotification', notifyAccount)
      const { handle } = notifyAccount
      return (

        <Snackbar
          open={notification !== null}
          onClose={() => { setNotification(null) }}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <SnackbarContent
            style={{ backgroundColor: 'blue', border: '1px solid white' }}
            message={<><div>APP: {title}</div><div>{body}</div></>}
            action={<div style={{ cursor: 'pointer', color: 'gold' }} onClick={() => {
              setNotification(null)
              history.push(`${process.env.REACT_APP_HARMONIZE_HOST}/@${handle}`)
            }}>
              <i>Click to enter your studio</i>
            </div>} />
        </Snackbar>
      )
    }
  }
  const displayApp = () => (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div>
          <Switch>
            <Route exact path='/' render={() => renderHome()} />
            <Route
              exact
              path='/CreateRoom'
              render={() => {
                if (isAuth()) {
                  return <CreateRoom />
                }
                return <SignIn returnPath='CreateRoom' />
              }}
            />
            <Route
              exact
              path='/CreateCollection/:messageId'
              render={() => {
                if (isAuth()) {
                  return <CreateCollection />
                }
                return <SignIn returnPath='CreateCollection' />
              }}
            />
            <Route
              exact
              path='/EditCollection/:collectionId'
              render={() => {
                if (isAuth()) {
                  return <CreateCollection />
                }
                return <SignIn returnPath='CreateCollection' />
              }}
            />

            <Route
              exact
              path='/embed/:roomId'
              component={EmbedAI}
            />
            <Route
              exact
              path='/About'
              component={About}
            />

            <Route
              exact
              path='/PressRelease'
              component={PressRelease}
            />
            {/*
            <Route
              exact
              path='/help/AboutEmbeds'
              component={AboutEmbeds}
            />
            */}
            <Route
              exact
              path='/help/AboutStudios'
              component={AboutStudios}
            />
            <Route
              exact
              path='/help/Approvals'
              component={Approvals}
            />
            <Route
              exact
              path='/Contact/:initialMessage?'
              component={Contact}
            />

            <Route exact path='/Artists' component={Artists} />
            <Route exact path='/Pricing' component={Pricing} />
            <Route exact path='/Privacy' render={() => <Privacy />} />
            <Route exact path='/Credits' render={() => <Credits />} />

            {/*
            <Route exact path='/firstroom/:requestedRoom' render={() =>
              isAuth() ? <Studio startWithPost={true} /> : <SignIn />} />

            <Route exact path='/airoom/:requestedRoom' render={() =>
              isAuth() ? <Studio startWithAI={true} /> : <SignIn />} />
            */}

            <Route exact path='/private/:requestedRoom' render={() =>
              isAuth() ? <Studio startWithPost={true} /> : <SignIn />} />

            <Route exact path='/room/:requestedRoom' render={() =>
              <Studio />
            } />

            <Route exact path='/song/:songId' component={Studio} />
            {/*<Route exact path='/collection/:requestedRoom/:requestedCollectionId' component={Studio} />*/}
            <Route exact path='/publicroom/:requestedPublicRoom' component={Studio}
            />
            <Route exact path='/videoplayer' component={VideoPlayer} />
            <Route
              exact
              path='/TermsofService'
              render={() => <TermsOfService />}
            />
            <Route
              exact
              path='/EditRoom'
              render={() =>
                isAuth() ? <EditRoom /> : <SignIn returnPath='EditRoom' />
              }
            />

            <Route
              exact
              path='/Vault'
              render={() =>
                isAuth() ? <Vault /> : <SignIn returnPath='Vault' />
              }
            />

            <Route
              exact
              path='/Settings'
              render={() => (isAuth() ? <Settings /> : <SignIn />)}
            />
            <Route
              exact
              path='/SignIn/:returnTo?'
              render={() =>
                isAuth() ? (
                  <Redirect to='/' />
                ) : (
                  <SignIn />
                )
              }
            />

            <Route
              path='/firebase'
              component={EmailConfirmation}
            />

            <Route
              exact
              path='/ConfirmEmailAddress'
              component={ConfirmEmailAddress}
            />

            <Route
              exact
              path='/MyProfile'
              render={() => (isAuth() ? <MyProfile /> : <SignIn />)}
            />

            <Route
              exact
              path='/Studios'
              render={() => (isAuth() ? <Studios /> : <SignIn returnPath='Studios' />)}
            />

            <Route
              path='/UserProfile/:username'
              component={UserProfile}
            />

            <Route
              exact
              path='/BuyCredits'
              render={() => {
                if (isAuth()) {
                  return <BuyCredits />
                }
                return <SignIn returnPath='/BuyCredits' />
              }}
            />
            <Route
              exact
              path='/PaymentResult'
              render={() => {
                if (isAuth()) {
                  return <PaymentResult />
                }
                return <SignIn returnPath='PaymentResult' />
              }}
            />


            <Route
              exact
              path='/admin/ManageApprovals'
              render={() => {
                if (isAdminUser()) {
                  return <ManageApprovals />
                }
                return <Redirect to='/' />
              }}
            />

            <Route
              exact
              path='/admin/ManageThoughts'
              render={() => {
                if (isAdminUser()) {
                  return <ManageThoughts />
                }
                return <Redirect to='/' />
              }}
            />



            <Route exact path='/help/FAQ' component={FAQ} />
            {/* This route handles 404 errors */}
            <Route component={() => renderHome()} />
          </Switch>
          {displayNotification()}
        </div>
      </ThemeProvider>
    </BrowserRouter>
  )

  useEffect(() => {
    document.title = process.env.REACT_APP_NAME
  }, [])

  return displayApp()
}
